import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { toastSuccess } from '../../utils/toastManager';

function TestimonialForm({ user, courseId }) {
  const [showForm, setShowForm] = useState(false);
  const [testimonialMessage, setTestimonialMessage] = useState('');

  function postTestimonial(e) {
    e.preventDefault();

    fetch('/.netlify/functions/testimonials', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        courseId,
        email: user.email,
        message: testimonialMessage,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') {
          toastSuccess('Your message has been submitted for review!');
        }
      });
  }

  return (
    <div>
      <button
        onClick={() => setShowForm((prev) => !prev)}
        className={`
          mt-1 rounded border-2 border-color-2 p-1 font-ia transition-all ease-in-out

          hover:border-color-5
        `}
        type="button"
      >
        Post a Testimonial
      </button>
      {showForm && (
        <div className="mt-1 flex gap-1">
          <textarea
            className="rounded text-color-1"
            defaultValue={testimonialMessage}
            onChange={(e) => setTestimonialMessage(e.target.value)}
          />
          <button
            className={`
              rounded border-2 border-color-2 p-1 font-ia transition-all ease-in-out

              hover:border-color-5
            `}
            type="button"
            onClick={(e) => postTestimonial(e)}
          >
            Post
          </button>
        </div>
      )}
    </div>
  );
}

function TestimonialDisplay() {
  const [testimonials, setTestimonials] = useState();

  useEffect(() => {
    fetch(
      '/.netlify/functions/testimonials?courseId=65f14847b37f8ceea6fd9188',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
      .then((response) => response.json())
      .then((response) => {
        setTestimonials(response.filter((t) => t.published));
      });
  }, []);

  if (!testimonials || testimonials.length === 0) return <div />;
  return (
    <div>
      <Carousel
        interval={10000}
        transitionTime={1000}
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        infiniteLoop
        autoPlay
        dynamicHeight
        className="max-w-2xl"
      >
        {testimonials.map((t) => (
          <p className="pb-8 font-ia text-sm">{t.message}</p>
        ))}
      </Carousel>
    </div>
  );
}

export { TestimonialForm, TestimonialDisplay };
