import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from '../res/logo.svg';
import { useAuthContext } from '../utils/AuthContext';

function Nav() {
  const { auth } = useAuthContext();

  const [scrollY, setScrollY] = useState(0); // used for opacity
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => setScrollY(window.scrollY));
    return () => {
      window.removeEventListener('scroll', () => setScrollY(window.scrollY));
    };
  }, []);

  function logoLink() {
    return (
      <Link
        to="/"
        className="group flex items-center"
        onClick={() => setShowMobileMenu(false)}
      >
        <Logo
          className={`
            ${scrollY < 10 ? 'w-20 h-20' : 'w-10 h-10'}

            my-1 mr-4 fill-white transition-all transition-all ease-in-out

            group-hover:fill-color-5
          `}
        />
        <div
          className={`
            transition-all ease-in-out

            group-hover:text-color-5

            max-md:hidden
          `}
        >
          <p>
            <strong>O</strong>
            nline&nbsp;
            <strong>A</strong>
            cademy of
          </p>
          <p>
            <strong>C</strong>
            hinese&nbsp;
            <strong>A</strong>
            rts
          </p>
        </div>
      </Link>
    );
  }

  function navTab(tabName, url) {
    return (
      <Link
        onClick={() => setShowMobileMenu(false)}
        className={`
          mr-4 transition-all ease-in-out

          hover:text-color-5
        `}
        to={url}
      >
        {tabName}
      </Link>
    );
  }

  return (
    <div
      className={`
        ${scrollY < 10 ? '' : 'opacity-95'}

        fixed top-0 z-50 flex w-screen items-center justify-between gap-4 whitespace-nowrap
        bg-color-1 px-2 py-1 font-ia text-white

        max-sm:flex-col

        md:px-20
      `}
    >
      <div
        className={`
          flex w-full

          max-sm:justify-between
        `}
      >
        {logoLink()}
        <button
          onClick={() => setShowMobileMenu((prev) => !prev)}
          type="button"
          className={`
            transition-all ease-in-out

            hover:text-color-5

            sm:hidden
          `}
          alt="menu"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="mr-2 h-8 w-8"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
      <div
        className={`
          flex

          max-sm:flex max-sm:grow max-sm:flex-col max-sm:gap-4

          ${showMobileMenu ? 'max-sm:h-screen' : 'max-sm:hidden'}
        `}
      >
        {navTab('Courses', '/courses')}
        {navTab('Instructors', '/instructors')}
        {navTab('Posts', '/posts')}
        {navTab(
          `${!auth || !auth.currentUser ? 'Login' : 'Account'}`,
          '/account',
        )}
      </div>
    </div>
  );
}

export default Nav;
