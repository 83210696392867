import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

/**
 * Determines the shadow style for a specific section based on surrounding sections.
 *
 * @param {Array} data - The array of section data.
 * @param {number} index - The index of the current section.
 * @return {string} The shadow style for the section.
 */
function sectionShadow(data, index) {
  const prevSection = data[index - 1];
  const nextSection = data[index + 1];
  if (
    // eslint-disable-next-line operator-linebreak
    (!prevSection || prevSection.type === 'text') &&
    (!nextSection || nextSection.type === 'text')
  ) {
    return 'shadow-section';
  }
  if (!prevSection || prevSection.type === 'text') {
    return 'shadow-sectionTop';
  }
  if (!nextSection || nextSection.type === 'text') {
    return 'shadow-sectionBot';
  }
  return '';
}

/**
 * Generates HTML content based on the content array.
 * content.type = 'text' | 'image' | 'video'
 *
 * @param {array} contentArr - Array of content objects
 * @return {JSX.Element} Generated HTML content
 */
export function contentHTMLGenerator(contentArr) {
  if (!contentArr) return <div />;
  return (
    <div>
      {contentArr.map((content, index) => {
        if (content.type === 'image') {
          const shadow = sectionShadow(contentArr, index);
          return (
            <div
              className={`
                flex w-full justify-center bg-color-4 p-4

                ${shadow}
              `}
            >
              <img
                className="max-h-96 overflow-hidden rounded border-2 border-white object-contain"
                src={`${content.value}`}
                alt="post"
              />
            </div>
          );
        }
        if (content.type === 'video') {
          const shadow = sectionShadow(contentArr, index);
          return (
            <div
              className={`
                justify-center bg-color-4 p-4

                ${shadow}
              `}
            >
              <div className="m-auto aspect-video max-w-2xl">
                <iframe
                  className="h-full w-full"
                  src={content.value}
                  allow="fullscreen"
                  title={`video-${index}`}
                />
              </div>
            </div>
          );
        }
        return (
          <div className="w-full bg-color-2 py-4 text-white">
            <p className="mx-auto max-w-2xl px-2">{parse(content.value)}</p>
          </div>
        );
      })}
    </div>
  );
}

/**
 * Generates HTML for a card container based on the provided card data array.
 *
 * @param {Array} cardDataArr - An array of card data objects
 * @return {JSX} HTML for the card container
 */
export function cardContainerHTMLGenerator(cardDataArr) {
  if (!cardDataArr) return <div />;
  return (
    <div
      className={`
        mx-auto flex w-full flex-wrap justify-center gap-4 bg-color-4 py-8 text-center font-ia
        text-white shadow-section
      `}
    >
      {cardDataArr.map((cardData) => (
        <Link
          className={
            !cardData.styles || cardData.styles === ''
              ? `
            w-64 flex-none rounded bg-color-2 p-4 transition-all ease-in-out

            hover:-translate-y-2 hover:shadow-card
          `
              : cardData.styles
          }
          to={`${cardData.slug}${cardData._id}`}
        >
          <img
            className="mb-2 rounded border-2 border-white"
            src={`${cardData.coverImageURL}/m/240x240`}
            alt="instructor-cover"
          />
          <div>{cardData.text_1}</div>
          <div className="text-xs">{cardData.text_2}</div>
        </Link>
      ))}
    </div>
  );
}
