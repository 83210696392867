import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import React, {
  createContext,
  useContext,
  useEffect,
  useState, //
} from 'react';

const Context = createContext(null);

export function AuthContext({ children }) {
  const [contextValue, setContextValue] = useState({});

  // auth uses firebase
  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    setContextValue((prev) => ({ ...prev, auth }));

    onAuthStateChanged(auth, (user) => {
      if (user) {
        setContextValue((prev) => ({ ...prev, currentUser: user }));
      } else {
        setContextValue((prev) => ({ ...prev, currentUser: null }));
      }
    });
  }, []);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}

export const useAuthContext = () => useContext(Context);
