/* eslint-disable no-console */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useAuthContext } from '../../utils/AuthContext';
import User from './user/User';
import Transaction from './transaction/Transaction';
import Instructor from './instructor/Instructor';
import Course from './course/Course';
import Post from './post/Post';
import Lesson from './lesson/Lesson';
import Testimonial from './testimonial/Testimonial';
import Promotion from './promotion/Promotion';

function RecordList({ collectionName }) {
  const basicFields = {
    users: ['_id', 'email', 'receivePromotionalEmails', 'createdDate'],
    transactions: ['_id', 'dateTime', 'userId', 'courseId', 'amount'],
    instructors: ['_id', 'displayName'],
    courses: ['_id', 'title', 'language', 'cost', 'draft'],
    posts: ['_id', 'date', 'title'],
    lessons: ['_id', 'title', 'free'],
    testimonials: ['_id', 'courseId', 'userId', 'message', 'published'],
    promotions: [
      '_id',
      'startDate',
      'endDate',
      'type',
      'value',
      'note',
      'courseIds',
    ],
  };

  const { auth } = useAuthContext();
  const [records, setRecords] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);

  const localTabSettings = localStorage.getItem('adminTabSettings')
    ? JSON.parse(localStorage.getItem('adminTabSettings'))[collectionName]
    : null;
  const [offset, setOffset] = useState(localTabSettings?.offset || 0);
  const [limit, setLimit] = useState(localTabSettings?.limit || 50);
  const [orderBy, setOrderBy] = useState(localTabSettings?.orderBy);
  const [asc, setAsc] = useState(localTabSettings?.asc || 1);

  const [courseIdsMapTitles, setcourseIdsMapTitles] = useState([]);
  const [userIdsMapEmails, setuserIdsMapEmails] = useState([]);

  // get specific tab settings from local storage on tab change
  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('adminTabSettings')) || {};
    if (settings[collectionName]) {
      setOffset(settings[collectionName].offset);
      setLimit(settings[collectionName].limit);
      setOrderBy(settings[collectionName].orderBy);
      setAsc(settings[collectionName].asc);
    }
  }, [collectionName]);

  // save specific tab settings to local storage on change
  useEffect(() => {
    const originalSettings =
      JSON.parse(localStorage.getItem('adminTabSettings')) || {};
    localStorage.setItem(
      'adminTabSettings',
      JSON.stringify({
        ...originalSettings,
        [collectionName]: {
          offset,
          limit,
          orderBy,
          asc,
        },
      }),
    );
  }, [offset, limit, orderBy, asc]);

  async function fetchRecords() {
    if (!auth || !auth.currentUser) return;

    // fetch records
    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          offset,
          limit,
          orderBy,
          asc,
          fields: basicFields[collectionName],
          collectionName,
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setRecords(() => response.records);
        setTotalRecords(response.total);

        // fetch course names from ids
        if (basicFields[collectionName].includes('courseId')) {
          fetch('/.netlify/functions/admin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: auth.currentUser.email,
              payload: auth.currentUser.uid,
              route: 'FIND',
              params: {
                fields: ['title'],
                ids: response.records.map((record) => record.courseId),
                collectionName: 'courses',
              },
            }),
          })
            .then((responseCourses) => responseCourses.json())
            .then((responseCourses) => {
              const map = {};
              responseCourses.records.forEach((record) => {
                map[record._id] = record.title;
              });
              setcourseIdsMapTitles(map);
            });
        }
        // fetch usernames from ids
        if (basicFields[collectionName].includes('courseId')) {
          fetch('/.netlify/functions/admin', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: auth.currentUser.email,
              payload: auth.currentUser.uid,
              route: 'FIND',
              params: {
                fields: ['email'],
                ids: response.records.map((record) => record.userId),
                collectionName: 'users',
              },
            }),
          })
            .then((responseUsers) => responseUsers.json())
            .then((responseUsers) => {
              const map = {};
              responseUsers.records.forEach((record) => {
                map[record._id] = record.email;
              });
              setuserIdsMapEmails(map);
            });
        }
      });
  }
  useEffect(() => {
    fetchRecords();
  }, [auth?.currentUser, collectionName, offset, limit, orderBy, asc]);

  function individualRecordComp(id) {
    switch (collectionName) {
      case 'users':
        return <User id={id} />;
      case 'transactions':
        return <Transaction id={id} />;
      case 'instructors':
        return <Instructor id={id} />;
      case 'courses':
        return <Course id={id} />;
      case 'posts':
        return <Post id={id} />;
      case 'lessons':
        return <Lesson id={id} />;
      case 'testimonials':
        return <Testimonial id={id} />;
      case 'promotions':
        return <Promotion id={id} />;
      default:
        console.error('record type not found');
        return <div />;
    }
  }

  function newButton() {
    return (
      <button
        type="button"
        className={`
          rounded border-2 border-color-1 px-2 py-1 bg-color-white transition-all ease-in-out

          active:opacity-50

          hover:border-color-5 hover:bg-white hover:text-color-1
        `}
      >
        New
      </button>
    );
  }

  function paramButtons() {
    return (
      <div className="flex justify-between">
        <Popup modal nested trigger={newButton()}>
          {individualRecordComp()}
        </Popup>
        <div className="flex items-center gap-2">
          <div className="flex gap-2">
            <div>Page:</div>
            <button
              className={`
                active:opacity-50

                hover:text-color-5

                ${offset === 0 ? 'text-gray-400 cursor-default' : ''}
              `}
              type="button"
              onClick={
                () =>
                  setOffset((prev) => {
                    const newPrev = prev - 1;
                    return newPrev < 0 ? 0 : newPrev;
                  }) //
              }
            >
              {'<'}
            </button>
            <div>{`${offset + 1} / ${Math.ceil(totalRecords / limit)}`}</div>
            <button
              className={`
                active:opacity-50

                hover:text-color-5

                ${
                  offset === Math.ceil(totalRecords / limit) - 1
                    ? 'text-gray-400 cursor-default'
                    : ''
                }
              `}
              type="button"
              onClick={
                () =>
                  setOffset((prev) => {
                    const newPrev = prev + 1;
                    return newPrev >= Math.ceil(totalRecords / limit)
                      ? prev
                      : newPrev;
                  }) //
              }
            >
              {'>'}
            </button>
          </div>
          <div>|</div>
          <div className="flex gap-2">
            <div>Records Per Page:</div>
            <button
              className={`
                active:opacity-50

                hover:text-color-5

                ${limit !== 20 ? 'text-gray-400 cursor-default' : ''}
              `}
              type="button"
              onClick={() => setLimit(20)}
            >
              20
            </button>
            <button
              className={`
                active:opacity-50

                hover:text-color-5

                ${limit !== 50 ? 'text-gray-400 cursor-default' : ''}
              `}
              type="button"
              onClick={() => setLimit(50)}
            >
              50
            </button>
            <button
              className={`
                active:opacity-50

                hover:text-color-5

                ${limit !== 100 ? 'text-gray-400 cursor-default' : ''}
              `}
              type="button"
              onClick={() => setLimit(100)}
            >
              100
            </button>
          </div>
        </div>
      </div>
    );
  }

  function columnHeaders() {
    return (
      <>
        <div className="border-b-2 border-b-white" />
        <div className="border-b-2 border-b-white" />
        {basicFields[collectionName].map((field) => (
          <button
            type="button"
            onClick={() => {
              if (orderBy === field) setAsc((prev) => (prev === 1 ? -1 : 1));
              else setOrderBy(field);
            }}
            className={`
              border-b-2 border-b-white pr-1 text-left

              active:opacity-50

              hover:text-color-5
            `}
          >
            {field}
          </button>
        ))}
      </>
    );
  }

  function recordsGrid() {
    function editButton() {
      return (
        <button
          type="button"
          alt="edit"
          className={`
            h-6 w-6

            active:opacity-50

            hover:text-color-5
          `}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
        </button>
      );
    }

    // because tailwind isnt working with template strings using custom values
    function gridClassName() {
      switch (collectionName) {
        case 'users':
          return 'grid grid-cols-[2rem,2rem,repeat(4,1fr)]';
        case 'transactions':
          return 'grid grid-cols-[2rem,2rem,repeat(5,1fr)]';
        case 'instructors':
          return 'grid grid-cols-[2rem,2rem,repeat(2,1fr)]';
        case 'courses':
          return 'grid grid-cols-[2rem,2rem,repeat(5,1fr)]';
        case 'posts':
          return 'grid grid-cols-[2rem,2rem,repeat(3,1fr)]';
        case 'lessons':
          return 'grid grid-cols-[2rem,2rem,repeat(3,1fr)]';
        case 'testimonials':
          return 'grid grid-cols-[2rem,2rem,repeat(5,1fr)]';
        case 'promotions':
          return 'grid grid-cols-[2rem,2rem,repeat(7,1fr)]';
        default:
          return 'grid grid-cols-[2rem,2rem,repeat(4,1fr)]';
      }
    }

    function displayValue(record, field) {
      if (
        typeof record[field] !== 'object' &&
        typeof record[field] !== 'boolean'
      ) {
        if (field === 'courseId') return courseIdsMapTitles[record[field]];
        if (field === 'userId') return userIdsMapEmails[record[field]];
        if (field === 'dateTime') {
          return record[field].replaceAll('T', ' ').slice(0, 16);
        }
        return record[field];
      }

      if (typeof record[field] === 'object') {
        return JSON.stringify(record[field]);
      }

      if (typeof record[field] === 'boolean') {
        return record[field] ? 'true' : '';
      }

      console.error('field not found: ', field);
      return '???';
    }

    return (
      <div className="bg-color-2 p-4 text-white">
        {paramButtons()}
        <div className={gridClassName()}>
          {columnHeaders()}
          {records?.map((record, index) => (
            <>
              {/* edit button */}
              <Popup modal nested trigger={editButton()}>
                {individualRecordComp(record._id)}
              </Popup>
              {/* index */}
              <div className="overflow-hidden text-ellipsis text-nowrap">
                {index + offset * limit + 1}
              </div>
              {/* fields */}
              {basicFields[collectionName].map((field) => (
                <div className="overflow-hidden text-ellipsis text-nowrap">
                  {displayValue(record, field)}
                </div>
              ))}
            </>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h1 className="my-4 text-center text-3xl capitalize">{collectionName}</h1>
      {recordsGrid()}
    </div>
  );
}

export default RecordList;
