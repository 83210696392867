import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { cardContainerHTMLGenerator } from '../../utils/componentGeneration';

function Instructors() {
  const [instructors, setInstructors] = useState();

  useEffect(() => {
    document.title = 'Instructors';
  }, []);
  async function fetchData() {
    await fetch(
      '/.netlify/functions/instructors?fields=displayName&fields=subtitle&fields=coverImageURL',
    )
      .then((response) => response.json())
      .then((response) => setInstructors(response));
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <h1 className="mt-4 w-full text-center text-3xl">Instructors</h1>
      <div className="flex justify-center">
        <BarLoader color="#023059" className="m-4" loading={!instructors} />
      </div>
      {cardContainerHTMLGenerator(
        instructors?.map((instructor) => ({
          _id: instructor._id,
          coverImageURL: instructor.coverImageURL,
          slug: '/instructors/',
          text_1: instructor.displayName,
          text_2: instructor.subtitle,
        })),
      )}
    </div>
  );
}

export default Instructors;
