import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useAuthContext } from '../../../utils/AuthContext';
import CoursesSelectionPopup from '../../../utils/selectionPopups/CoursesSelectionPopup';
import { toastSuccess } from '../../../utils/toastManager';
import { formButtons } from '../formComponents';

function Promotion({ id }) {
  const { auth } = useAuthContext();

  // form inputs
  const [formStartDate, setFormStartDate] = useState('');
  const [formEndDate, setFormEndDate] = useState('');
  const [formCourseIds, setFormCourseIds] = useState([]);
  const [formType, setFormType] = useState('flat');
  const [formValue, setFormValue] = useState(0);
  const [formNote, setFormNote] = useState('');

  const [courses, setCourses] = useState();

  const [openCoursesPopup, setOpenCoursesPopup] = useState({});

  async function fetchRecords() {
    if (!auth || !auth.currentUser) return;

    // fetch records
    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [id],
          collectionName: 'promotions',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setFormStartDate(response.records[0]?.startDate || '');
        setFormEndDate(response.records[0]?.endDate || '');
        setFormCourseIds(response.records[0]?.courseIds || []);
        setFormType(response.records[0]?.type || 'flat');
        setFormValue(response.records[0]?.value || 0);
        setFormNote(response.records[0]?.note || '');
      });

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [],
          orderBy: 'title',
          asc: 1,
          fields: ['_id', 'title'],
          collectionName: 'courses',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setCourses(response.records);
      });
  }

  useEffect(() => {
    fetchRecords();
  }, [auth?.currentUser, id]);

  function handleSave(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'UPDATE_ONE',
        params: {
          id,
          valuesObj: {
            startDate: formStartDate,
            endDate: formEndDate,
            courseIds: formCourseIds,
            type: formType,
            value: parseFloat(formValue),
            note: formNote,
          },
          collectionName: 'promotions',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Success!');
      });
  }

  function handleDelete(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'DELETE_ONE',
        params: {
          id,
          collectionName: 'promotions',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Deleted!');
      });
  }

  function detailsForm() {
    return (
      <form className="bg-color-2 px-4 py-2 text-white">
        <div className="grid grid-cols-[1fr,2fr] pt-4">
          <label className="mb-2 mr-2 text-right text-gray-300" htmlFor="id">
            ID:
          </label>
          <input
            className="mb-2 rounded bg-color-2 outline-none"
            type="text"
            id="id"
            name="id"
            defaultValue={id}
            readOnly
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formStartDate"
          >
            Start Date:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="date"
            id="startDate"
            name="formStartDate"
            defaultValue={formStartDate}
            onChange={(e) => setFormStartDate(e.target.value)}
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formEndDate"
          >
            End Date:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="date"
            id="endDate"
            name="formEndDate"
            defaultValue={formEndDate}
            onChange={(e) => setFormEndDate(e.target.value)}
          />
          <label className="mr-2 text-right text-gray-300">Type:</label>
          <div className="flex items-top gap-1">
            <input
              defaultChecked
              checked={formType === 'percentage'}
              className="h-6"
              type="radio"
              name="percentage"
              onChange={() => setFormType('percentage')}
            />
            Percentage
            <input
              defaultChecked
              checked={formType === 'flat'}
              className="h-6"
              type="radio"
              name="flat"
              onChange={() => setFormType('flat')}
            />
            Flat
          </div>
          <label className="mr-2 text-right text-gray-300" htmlFor="formValue">
            Value:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="number"
            id="formValue"
            name="formValue"
            value={formValue /* input value not updating with defaultValue */}
            onChange={(e) => setFormValue(e.target.value)}
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formLanguage"
          >
            Note:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="string"
            id="formNote"
            name="formNote"
            defaultValue={formNote}
            onChange={(e) => setFormNote(e.target.value)}
          />

          {/* Courses */}
          <div className="flex flex-col items-end">
            <label className="mr-2 text-right text-gray-300">Courses:</label>
            <button
              type="button"
              className={`
                mr-2 max-w-max rounded border-2 border-color-1 px-1 bg-color-white transition-all
                ease-in-out

                active:opacity-50

                hover:border-color-5 hover:bg-white hover:text-color-1
              `}
              onClick={() => setFormCourseIds((prev) => prev.concat(''))}
            >
              Add
            </button>
          </div>
          <div className="flex flex-col">
            {formCourseIds?.map((courseId, index) => (
              <div className="flex flex-col gap-1">
                <div className="grid grid-cols-[9fr,2rem]">
                  <button
                    onClick={
                      () =>
                        setOpenCoursesPopup((prev) => ({
                          ...prev,
                          [index]: true,
                        }))
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    className={`
                      mb-2 h-6 rounded bg-color-1 px-1 text-left

                      focus:outline-none focus:ring-2 focus:ring-color-5
                    `}
                    type="button"
                  >
                    {courses?.filter((l) => l._id === courseId)[0]?.title}
                  </button>
                  <Popup modal open={openCoursesPopup[index]} nested>
                    <CoursesSelectionPopup
                      courses={courses}
                      formCourseIds={formCourseIds}
                      setFormCourseIds={setFormCourseIds}
                      index={index}
                      setOpenCoursesPopup={setOpenCoursesPopup}
                    />
                  </Popup>

                  <button
                    type="button"
                    className={`
                      ml-1 h-6 rounded border-2 border-color-1 px-1 bg-color-white transition-all
                      ease-in-out

                      active:opacity-50

                      hover:border-color-5 hover:bg-color-4 hover:text-white
                    `}
                    onClick={
                      () =>
                        setFormCourseIds((prev) =>
                          prev.filter((_, i) => i !== index),
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* END Courses */}
        </div>
        {formButtons(id, handleDelete, handleSave)}
      </form>
    );
  }

  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] overflow-y-scroll rounded border-4 border-color-4 bg-white pt-4
      `}
    >
      <h1 className="px-4">Promotion Details</h1>
      {detailsForm()}
    </div>
  );
}

export default Promotion;
