import React from 'react';

function formButtons(id, handleDelete, handleSave) {
  return (
    <div
      className={`
        flex

        ${id ? 'justify-between' : 'justify-end'}
      `}
    >
      {id && (
        <button
          type="submit"
          className={`
            rounded border-2 border-color-1 px-2 py-1 bg-color-white transition-all ease-in-out

            active:opacity-50

            hover:border-color-5 hover:bg-color-4 hover:text-white
          `}
          onClick={(e) => handleDelete(e)}
        >
          Delete
        </button>
      )}
      <button
        type="submit"
        className={`
          rounded border-2 border-color-1 px-2 py-1 bg-color-white transition-all ease-in-out

          active:opacity-50

          hover:border-color-5 hover:bg-white hover:text-color-1
        `}
        onClick={(e) => handleSave(e)}
      >
        Save
      </button>
    </div>
  );
}

function formContentForm(formContent, setFormContent) {
  return (
    <>
      <div className="flex flex-col items-end">
        <label className="mr-2 text-right text-gray-300">Content:</label>
        <button
          type="button"
          className={`
            mr-2 max-w-max rounded border-2 border-color-1 px-1 bg-color-white transition-all
            ease-in-out

            active:opacity-50

            hover:border-color-5 hover:bg-white hover:text-color-1
          `}
          onClick={
            () =>
              setFormContent((prev) => prev.concat({ type: 'text', value: '' }))
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          Add
        </button>
      </div>
      <div className="flex flex-col">
        {formContent?.map((content, index) => (
          <div className="flex flex-col gap-1">
            <div className="grid grid-cols-[9fr,2rem]">
              <div className="flex items-top gap-1">
                <input
                  defaultChecked
                  checked={content?.type === 'text'}
                  className="h-6"
                  type="radio"
                  name={`${index}contentType`}
                  onChange={
                    () =>
                      setFormContent((prev) => {
                        const newPrev = [...prev];
                        newPrev[index].type = 'text';
                        return newPrev;
                      })
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                Text
                <input
                  checked={content?.type === 'image'}
                  className="h-6"
                  type="radio"
                  name={`${index}contentType`}
                  onChange={
                    () =>
                      setFormContent((prev) => {
                        const newPrev = [...prev];
                        newPrev[index].type = 'image';
                        return newPrev;
                      })
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                Image
                <input
                  checked={content?.type === 'video'}
                  className="h-6"
                  type="radio"
                  name={`${index}contentType`}
                  onChange={
                    () =>
                      setFormContent((prev) => {
                        const newPrev = [...prev];
                        newPrev[index].type = 'video';
                        return newPrev;
                      })
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                />
                Video
              </div>
              <button
                type="button"
                className={`
                  ml-1 h-6 rounded border-2 border-color-1 px-1 bg-color-white transition-all
                  ease-in-out

                  active:opacity-50

                  hover:border-color-5 hover:bg-color-4 hover:text-white
                `}
                onClick={
                  () =>
                    setFormContent((prev) => prev.filter((_, i) => i !== index))
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              >
                -
              </button>
            </div>
            {content?.type === 'text' ? (
              <textarea
                className={`
                  mb-2 resize rounded bg-color-1 px-1

                  focus:outline-none focus:ring-2 focus:ring-color-5
                `}
                id="setFormContent"
                name="setFormContent"
                defaultValue={content?.value}
                onChange={
                  (e) =>
                    setFormContent((prev) =>
                      prev.map((u, i) =>
                        i === index
                          ? { type: u.type, value: e.target.value }
                          : u,
                      ),
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            ) : (
              <input
                className={`
                  mb-2 rounded bg-color-1 px-1

                  focus:outline-none focus:ring-2 focus:ring-color-5
                `}
                id="setFormContent"
                name="setFormContent"
                defaultValue={content?.value}
                onChange={
                  (e) =>
                    setFormContent((prev) =>
                      prev.map((u, i) =>
                        i === index
                          ? { type: u.type, value: e.target.value }
                          : u,
                      ),
                    )
                  // eslint-disable-next-line react/jsx-curly-newline
                }
              />
            )}
            {content?.type === 'image' && (
              <img
                className="mb-2 w-[200px] rounded"
                alt="imageUrl"
                src={`${content?.value}/m/200x0`}
              />
            )}
            {content?.type === 'video' && (
              <div className="mb-2 aspect-video w-[200px] rounded">
                <iframe
                  className="h-full w-full rounded"
                  src={content?.value}
                  allow="fullscreen"
                  title={`video-${index}`}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

function formDurationForm(formDuration, setFormDuration) {
  return (
    <>
      <label className="mr-2 text-right text-gray-300" htmlFor="duration">
        Duration:
      </label>
      <div className="flex items-top gap-1">
        <input
          className={`
            mb-2 mr-2 rounded bg-color-1 px-1

            focus:outline-none focus:ring-2 focus:ring-color-5
          `}
          type="number"
          id="duration"
          name="duration"
          value={
            formDuration?.value /* input value not updating with defaultValue */
          }
          onChange={
            (e) =>
              setFormDuration((prev) => ({
                type: prev.type,
                value: e.target.value,
              }))
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
        <input
          defaultChecked
          checked={formDuration?.type === 'day'}
          className="h-6"
          type="radio"
          name="durationType"
          onChange={
            () =>
              setFormDuration((prev) => ({
                type: 'day',
                value: prev?.value,
              }))
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
        Day
        <input
          checked={formDuration?.type === 'month'}
          className="h-6"
          type="radio"
          name="durationType"
          onChange={
            () =>
              setFormDuration((prev) => ({
                type: 'month',
                value: prev?.value,
              }))
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
        Month
        <input
          checked={formDuration?.type === 'year'}
          className={`
            h-6

            focus:ring-color-5
          `}
          type="radio"
          name="durationType"
          onChange={
            () =>
              setFormDuration((prev) => ({
                type: 'year',
                value: prev?.value,
              }))
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
        Year
      </div>
    </>
  );
}

export { formButtons, formContentForm, formDurationForm };
