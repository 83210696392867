import React, { useEffect } from 'react';

export default function ErrorPage() {
  useEffect(() => {
    document.title = 'Oops!';
  }, []);
  return (
    <div className="m-4 w-full text-center">
      <h1>Oops!</h1>
      <p>Sorry, the page was not found.</p>
    </div>
  );
}
