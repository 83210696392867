import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="bg-color-1 text-white">
      <div className="flex justify-center text-xs">
        <div>{`© ${new Date().getFullYear()} Online Academy of Chinese Arts`}</div>
        <div>&nbsp;|&nbsp;</div>
        <Link
          className={`
            transition-all ease-in-out

            hover:text-color-5
          `}
          to="disclaimer"
        >
          Disclaimer
        </Link>
        <div>&nbsp;|&nbsp;</div>
        <Link
          className={`
            transition-all ease-in-out

            hover:text-color-5
          `}
          to="privacy"
        >
          Privacy
        </Link>
        <div>&nbsp;|&nbsp;</div>
        <Link
          className={`
            transition-all ease-in-out

            hover:text-color-5
          `}
          to="cookies"
        >
          Cookies
        </Link>
      </div>
    </div>
  );
}

export default Footer;
