import toast from 'react-hot-toast';

export function toastError(message) {
  toast(message, {
    duration: 3000,
    position: 'top-left',

    icon: '❌',

    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },

    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
}

export function toastSuccess(message) {
  toast(message, {
    duration: 3000,
    position: 'top-left',

    icon: '✔️',

    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },

    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
}

export function toastGeneric(message) {
  toast(message, {
    duration: 3000,
    position: 'top-left',

    iconTheme: {
      primary: '#000',
      secondary: '#fff',
    },

    ariaProps: {
      role: 'status',
      'aria-live': 'polite',
    },
  });
}
