import React, { useEffect } from 'react';

function Cookies() {
  useEffect(() => {
    document.title = 'Cookies';
  }, []);
  return (
    <div>
      <div>
        <div className="w-full">
          <h2 className="m-auto max-w-2xl p-4 text-3xl">Cookie Policy</h2>
        </div>
        <div className="bg-color-2">
          <div className="m-auto flex max-w-2xl flex-col gap-2 p-4 text-white">
            <div className="mt-4 flex flex-col gap-2">
              <p>
                This is the Cookie Policy for Online Academy of Chinese Arts,
                accessible from onlineacademyofchinesearts.com
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>1. What Are Cookies</h3>

              <p>
                As is common practice with almost all professional websites this
                site uses cookies, which are tiny files that are downloaded to
                your computer, to improve your experience. This page describes
                what information they gather, how we use it and why we sometimes
                need to store these cookies. We will also share how you can
                prevent these cookies from being stored however this may
                downgrade or &apos;break&apos; certain elements of the sites
                functionality. This Cookie Policy was created with the help of
                the&nbsp;
                <a href="https://www.cookiepolicygenerator.com/cookie-policy-generator/">
                  Cookie Policy Generator
                </a>
                .
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>2. How We Use Cookies</h3>

              <p>
                We use cookies for keeping track of your login session (so you
                don&apos;t have to login everytime you visit the site), saving
                your preferences, and as a part of Google Analytics.
                Unfortunately in most cases there are no industry standard
                options for disabling cookies without completely disabling the
                functionality and features they add to this site. It is
                recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a
                service that you use.
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>3. Disabling Cookies</h3>

              <p>
                You can prevent the setting of cookies by adjusting the settings
                on your browser (see your browser Help for how to do this). Be
                aware that disabling cookies will affect the functionality of
                this and many other websites that you visit. Disabling cookies
                will usually result in also disabling certain functionality and
                features of the this site. Therefore it is recommended that you
                do not disable cookies.
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>4. The Cookies We Set</h3>

              <div className="m-4 flex flex-col">
                <li>
                  <p>Login related cookies</p>
                  <p>
                    We use cookies when you are logged in so that we can
                    remember this fact. This prevents you from having to log in
                    every single time you visit a new page. These cookies are
                    typically removed or cleared when you log out to ensure that
                    you can only access restricted features and areas when
                    logged in.
                  </p>
                </li>

                <li>
                  <p>Site preferences cookies</p>
                  <p>
                    In order to provide you with a great experience on this site
                    we provide the functionality to set your preferences for how
                    this site runs when you use it. In order to remember your
                    preferences we need to set cookies so that this information
                    can be called whenever you interact with a page is affected
                    by your preferences.
                  </p>
                </li>
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>5. Third Party Cookies</h3>

              <p>
                In some special cases we also use cookies provided by trusted
                third parties. The following section details which third party
                cookies you might encounter through this site.
              </p>

              <div className="m-4 flex flex-col">
                <li>
                  <p>
                    This site uses Google Analytics which is one of the most
                    widespread and trusted analytics solution on the web for
                    helping us to understand how you use the site and ways that
                    we can improve your experience. These cookies may track
                    things such as how long you spend on the site and the pages
                    that you visit so we can continue to produce engaging
                    content.
                  </p>
                  <p>
                    For more information on Google Analytics cookies, see the
                    official Google Analytics page.
                  </p>
                </li>

                <li>
                  <p>
                    We also use social media buttons and/or plugins on this site
                    that allow you to connect with your social network in
                    various ways. For these to work, social media will set
                    cookies through our site which may be used to enhance your
                    profile on their site or contribute to the data they hold
                    for various purposes outlined in their respective privacy
                    policies.
                  </p>
                </li>
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>6. More Information</h3>

              <p>
                Hopefully that has clarified things for you and as was
                previously mentioned if there is something that you aren&apos;t
                sure whether you need or not it&apos;s usually safer to leave
                cookies enabled in case it does interact with one of the
                features you use on our site.
              </p>

              <p>
                For more general information on cookies, please read&nbsp;
                <a href="https://www.cookiepolicygenerator.com/sample-cookies-policy/">
                  the Cookies Policy article
                </a>
                .
              </p>

              <p>
                However if you are still looking for more information then you
                can contact us at info@onlineacademyofchinesearts.com.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cookies;
