import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useAuthContext } from '../../../utils/AuthContext';
import UserSelectionPopup from '../../../utils/selectionPopups/UserSelectionPopup';
import CourseSelectionPopup from '../../../utils/selectionPopups/CourseSelectionPopup';
import { toastSuccess } from '../../../utils/toastManager';
import { formButtons } from '../formComponents';

function Testimonial({ id }) {
  const { auth } = useAuthContext();

  // form inputs
  const [formCreatedDate, setFormCreatedDate] = useState();
  const [formUserId, setFormUserId] = useState('');
  const [formCourseId, setFormCourseId] = useState('');
  const [formMessage, setFormMessage] = useState('');
  const [formPublished, setFormPublished] = useState(false);

  const [users, setUsers] = useState();
  const [courses, setCourses] = useState();

  const [openUserIdPopup, setOpenUserIdPopup] = useState(false);
  const [openCourseIdPopup, setOpenCourseIdPopup] = useState(false);

  async function fetchRecords() {
    if (!auth || !auth.currentUser) return;

    // fetch records
    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [id],
          collectionName: 'testimonials',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setFormCreatedDate(response.records[0]?.dateTime);
        setFormUserId(response.records[0]?.userId || '');
        setFormCourseId(response.records[0]?.courseId || '');
        setFormMessage(response.records[0]?.message || '');
        setFormPublished(response.records[0]?.published);
      });

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [],
          orderBy: 'email',
          asc: 1,
          fields: ['_id', 'email'],
          collectionName: 'users',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setUsers(response.records);
      });

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [],
          orderBy: 'title',
          asc: 1,
          fields: ['_id', 'title'],
          collectionName: 'courses',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setCourses(response.records);
      });
  }
  useEffect(() => {
    fetchRecords();
  }, [auth?.currentUser, id]);

  function handleSave(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'UPDATE_ONE',
        params: {
          id,
          valuesObj: {
            userId: formUserId,
            courseId: formCourseId,
            createdDate: formCreatedDate || new Date(),
            message: formMessage,
            published: formPublished,
          },
          collectionName: 'testimonials',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Success!');
      });
  }

  function handleDelete(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'DELETE_ONE',
        params: {
          id,
          collectionName: 'testimonials',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Deleted!');
      });
  }

  function detailsForm() {
    return (
      <form className="bg-color-2 px-4 py-2 text-white">
        <div className="grid grid-cols-[1fr,2fr] pt-4">
          <label className="mb-2 mr-2 text-right text-gray-300" htmlFor="id">
            ID:
          </label>
          <input
            className="mb-2 rounded bg-color-2 outline-none"
            type="text"
            id="id"
            name="id"
            defaultValue={id}
            readOnly
          />
          <label className="mr-2 text-right text-gray-300" htmlFor="dateTime">
            Date Time:
          </label>
          <input
            className="mb-2 rounded bg-color-2 outline-none"
            type="string"
            id="dateTime"
            name="dateTime"
            defaultValue={formCreatedDate?.replace('T', ' ').slice(0, 19)}
            readOnly
          />

          <label className="mr-2 text-right text-gray-300" htmlFor="userId">
            User:
          </label>
          <button
            onClick={() => setOpenUserIdPopup(true)}
            className={`
              mb-2 h-6 rounded bg-color-1 px-1 text-left

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="button"
          >
            {users?.filter((u) => u._id === formUserId)[0]?.email}
          </button>
          <Popup nested modal open={openUserIdPopup}>
            <UserSelectionPopup
              users={users}
              formUserId={formUserId}
              setFormUserId={setFormUserId}
              setOpenUserIdPopup={setOpenUserIdPopup}
            />
          </Popup>

          <label className="mr-2 text-right text-gray-300" htmlFor="courseId">
            Course:
          </label>
          <button
            onClick={() => setOpenCourseIdPopup(true)}
            className={`
              mb-2 h-6 rounded bg-color-1 px-1 text-left

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="button"
          >
            {courses?.filter((c) => c._id === formCourseId)[0]?.title}
          </button>
          <Popup nested modal open={openCourseIdPopup}>
            <CourseSelectionPopup
              courses={courses}
              formCourseId={formCourseId}
              setFormCourseId={setFormCourseId}
              setOpenCourseIdPopup={setOpenCourseIdPopup}
            />
          </Popup>

          <label className="mr-2 text-right text-gray-300" htmlFor="message">
            Message:
          </label>
          <textarea
            className={`
              mb-2 resize rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            id="message"
            name="message"
            value={formMessage /* input value not updating with defaultValue */}
            onChange={(e) => setFormMessage(e.target.value)}
          />
          <label className="mr-2 text-right text-gray-300" htmlFor="published">
            Published:
          </label>
          <div className="flex items-center justify-start">
            <input
              className={`
                rounded bg-color-1 px-1

                focus:outline-none focus:ring-2 focus:ring-color-5
              `}
              checked={formPublished}
              type="checkbox"
              id="published"
              name="published"
              onChange={(e) => setFormPublished(e.target.checked)}
            />
          </div>
        </div>
        {formButtons(id, handleDelete, handleSave)}
      </form>
    );
  }

  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] overflow-y-scroll rounded border-4 border-color-4 bg-white pt-4
      `}
    >
      <h1 className="px-4">Testimonial Details</h1>
      {detailsForm()}
    </div>
  );
}

export default Testimonial;
