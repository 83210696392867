import React, { useState } from 'react';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { useAuthContext } from '../../utils/AuthContext';
import { ReactComponent as EyeClosed } from '../../res/eye-closed.svg';
import { ReactComponent as EyeOpen } from '../../res/eye-open.svg';
import { ReactComponent as BackIcon } from '../../res/back.svg';
import {
  toastError,
  toastGeneric,
  toastSuccess,
} from '../../utils/toastManager';

function AuthComponent() {
  const { auth } = useAuthContext();
  const [register, setRegister] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  /**
   * Handles the login process.
   *
   * @param {Event} e - the event triggering the function
   * @return {void}
   */
  function handleLogin(e) {
    e.preventDefault();
    signInWithEmailAndPassword(
      auth,
      e.target.email.value,
      e.target.password.value,
    ).catch(() => {
      toastError('Invalid Credentials');
    });
  }

  /**
   * Handles the registration process.
   * After registering with FireBase, adds user to database
   * and sends an email verification
   *
   * @param {Event} e - The event object.
   */
  async function handleRegister(e) {
    e.preventDefault();
    createUserWithEmailAndPassword(
      auth,
      e.target.email.value,
      e.target.password.value,
    )
      .then(async (response) => {
        toastSuccess('Registered!');
        sendEmailVerification(auth.currentUser).then(() => {
          toastGeneric('Please Verify Your Email');
        });
        fetch('/.netlify/functions/auth', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: response.user.email,
            receivePromotionalEmails: true,
            payload: response.user.uid,
          }),
        });
        setRegister(false);
      })
      .catch((error) => {
        if (error.code === 'auth/email-already-in-use') {
          toastError('Already Registered');
        } else {
          toastError('Oops Try Again');
        }
      });
  }

  /**
   * Handles the password reset.
   *
   * @param {Event} e - the event object
   * @return {void}
   */
  function handlePasswordReset(e) {
    e.preventDefault();
    sendPasswordResetEmail(auth, e.target.email.value).then(() => {
      toastSuccess('Password Reset Email Sent!');
    });
  }

  function authNav() {
    return (
      <div className="flex justify-between">
        <button
          className={`
            rounded-tr-2xl p-4 transition-all ease-in-out

            hover:text-color-5

            ${register ? '' : 'bg-color-1'}
          `}
          onClick={() => setRegister(false)}
          type="button"
        >
          Login
        </button>
        <div className="grow bg-color-1">
          <div
            className={`
              h-full w-full bg-color-2 transition-all ease-in-out

              ${register ? '' : 'rounded-bl-2xl'}
            `}
          />
        </div>
        <div className="grow bg-color-4">
          <div
            className={`
              h-full w-full bg-color-2 transition-all ease-in-out

              ${register ? 'rounded-br-2xl' : ''}
            `}
          />
        </div>
        <button
          className={`
            rounded-tl-2xl p-4 transition-all ease-in-out

            hover:text-color-5

            ${register ? 'bg-color-4' : ''}
          `}
          onClick={() => setRegister(true)}
          type="button"
        >
          Register
        </button>
      </div>
    );
  }

  function authForm() {
    return (
      <form
        className={`
          transition-all ease-in-out

          ${register ? 'bg-color-4' : 'bg-color-1'}

          p-4
        `}
        onSubmit={register ? handleRegister : handleLogin}
      >
        <label htmlFor="email">
          <div>Email</div>
          <input
            className="mb-2 h-16 w-full rounded px-2 text-2xl text-color-1"
            id="email"
            type="email"
          />
        </label>
        <label htmlFor="password">
          <div>Password</div>
          <input
            className="mb-4 h-16 w-full rounded px-2 text-2xl text-color-1"
            id="password"
            type={`${showPassword ? 'show' : 'password'}`}
          />
        </label>
        <button
          className="absolute -translate-x-full translate-y-1/2 cursor-pointer"
          type="button"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {!showPassword ? (
            <EyeClosed
              className={`
                my-1 mr-4 fill-color-1 transition-all transition-all ease-in-out

                hover:fill-color-5
              `}
            />
          ) : (
            <EyeOpen
              className={`
                my-1 mr-4 fill-black transition-all transition-all ease-in-out

                hover:fill-color-5
              `}
            />
          )}
        </button>
        <br />
        <div className="flex items-start justify-between">
          <button
            className={`
              rounded bg-color-2 p-4 text-xl transition-all ease-in-out

              hover:-translate-y-1 hover:shadow-card
            `}
            type="submit"
          >
            {register ? 'Register' : 'Login'}
          </button>
          {!register && (
            <button
              type="button"
              className={`
                cursor-pointer text-color-2 transition-all ease-in-out

                hover:text-color-5
              `}
              onClick={() => setResetPassword(true)}
            >
              Forgot Password?
            </button>
          )}
        </div>
      </form>
    );
  }

  function resetPasswordComp() {
    return (
      <>
        <button
          type="button"
          className={`
            group flex w-full items-center bg-color-2 p-2 transition-all ease-in-out

            hover:fill-color-5 hover:text-color-5
          `}
          onClick={() => setResetPassword(false)}
        >
          <BackIcon
            className={`
              fill-white transition-all ease-in-out

              group-hover:fill-color-5
            `}
          />
          <span>Back</span>
        </button>
        <div className="bg-color-1 px-4 py-4">
          <p>
            Forgot your password? We&apos;ll send you a password reset email:
          </p>
          <form onSubmit={handlePasswordReset}>
            <label htmlFor="email">
              <div>Email</div>
              <input
                className="mb-2 w-full rounded px-2 text-2xl text-color-1"
                id="email"
                type="email"
              />
            </label>
            <button
              className={`
                rounded bg-color-2 p-2 text-md transition-all ease-in-out

                hover:-translate-y-1 hover:shadow-card
              `}
              type="submit"
            >
              Reset
            </button>
          </form>
        </div>
      </>
    );
  }

  return (
    <div className="flex w-full items-center justify-center pt-24">
      <div className="m-2 overflow-hidden rounded-2xl bg-color-2 font-ia text-white">
        {!resetPassword ? (
          <>
            {authNav()}
            {authForm()}
          </>
        ) : (
          resetPasswordComp()
        )}
      </div>
    </div>
  );
}

export default AuthComponent;
