/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { cardContainerHTMLGenerator } from '../../utils/componentGeneration';
import { findBiggestPromotion } from '../../utils/dataManip';

function Courses() {
  const [courses, setCourses] = useState();
  const [promotions, setPromotions] = useState({});

  useEffect(() => {
    document.title = 'Courses';
  }, []);
  async function fetchData() {
    // fetch courses
    fetch('/.netlify/functions/courses?fields=title&fields=coverImageURL')
      .then((response) => response.json())
      .then((responseCourses) => {
        setCourses(responseCourses);

        // fetch promotions
        fetch('/.netlify/functions/promotions')
          .then((response) => response.json())
          .then(async (responsePromotions) => {
            // categorize promotions by course id
            const categorizedPromotions = {};
            responseCourses.forEach((course) => {
              if (!categorizedPromotions[course._id]) {
                categorizedPromotions[course._id] = [];
              }
              responsePromotions.forEach((promotion) => {
                if (promotion.courseIds.includes(course._id)) {
                  categorizedPromotions[course._id].push(promotion);
                }
              });
            });

            // only keep biggest promotion within date
            const categorizedBiggestPromotions = {};
            Object.keys(categorizedPromotions).forEach((key) => {
              categorizedBiggestPromotions[key] = findBiggestPromotion(
                categorizedPromotions[key],
                responseCourses.filter((course) => course._id === key)[0],
              );
            });
            setPromotions(categorizedBiggestPromotions);
          });
      });
  }
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <h1 className="mt-4 w-full text-center text-3xl">Courses</h1>
      <div className="flex justify-center">
        <BarLoader color="#023059" className="m-4" loading={!courses} />
      </div>
      {cardContainerHTMLGenerator(
        courses?.map((course) => {
          const styles = promotions[course._id]
            ? `
            w-64 flex-none rounded bg-color-5 p-4 transition-all ease-in-out

            hover:-translate-y-2 hover:shadow-card-highlight
          `
            : '';
          return {
            _id: course._id,
            coverImageURL: course.coverImageURL,
            slug: '/courses/',
            text_1: course.title,
            text_2: promotions[course._id]?.note,
            styles,
          };
        }),
      )}
    </div>
  );
}

export default Courses;
