import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../utils/AuthContext';
import { toastSuccess } from '../../../utils/toastManager';
import { formButtons, formContentForm } from '../formComponents';

function Lesson({ id }) {
  const { auth } = useAuthContext();

  // form inputs
  const [formTitle, setFormTitle] = useState('');
  const [formFree, setFormFree] = useState(false);
  const [formContent, setFormContent] = useState([]);

  async function fetchRecords() {
    if (!auth || !auth.currentUser) return;

    // fetch records
    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [id],
          collectionName: 'lessons',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setFormTitle(response.records[0]?.title || '');
        setFormFree(response.records[0]?.free);
        setFormContent(response.records[0]?.content || []);
      });
  }
  useEffect(() => {
    fetchRecords();
  }, [auth?.currentUser, id]);

  function handleSave(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'UPDATE_ONE',
        params: {
          id,
          valuesObj: {
            free: formFree,
            title: formTitle,
            content: formContent,
          },
          collectionName: 'lessons',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Success!');
      });
  }

  function handleDelete(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'DELETE_ONE',
        params: {
          id,
          collectionName: 'lessons',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Deleted!');
      });
  }

  function detailsForm() {
    return (
      <form className="bg-color-2 px-4 py-2 text-white">
        <div className="grid grid-cols-[1fr,2fr] pt-4">
          <label className="mb-2 mr-2 text-right text-gray-300" htmlFor="id">
            ID:
          </label>
          <input
            className="mb-2 rounded bg-color-2 outline-none"
            type="text"
            id="id"
            name="id"
            defaultValue={id}
            readOnly
          />
          <label className="mr-2 text-right text-gray-300" htmlFor="formTitle">
            Title:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="string"
            id="formTitle"
            name="formTitle"
            defaultValue={formTitle}
            onChange={(e) => setFormTitle(e.target.value)}
          />
          <label className="mr-2 text-right text-gray-300" htmlFor="formFree">
            Free:
          </label>
          <div className="flex items-center justify-start">
            <input
              className={`
                rounded bg-color-1 px-1

                focus:outline-none focus:ring-2 focus:ring-color-5
              `}
              checked={formFree}
              type="checkbox"
              id="formFree"
              name="formFree"
              onChange={(e) => setFormFree(e.target.checked)}
            />
          </div>

          {formContentForm(formContent, setFormContent)}
        </div>
        {formButtons(id, handleDelete, handleSave)}
      </form>
    );
  }

  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] overflow-y-scroll rounded border-4 border-color-4 bg-white pt-4
      `}
    >
      <h1 className="px-4">Lesson Details</h1>
      {detailsForm()}
    </div>
  );
}

export default Lesson;
