import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../utils/AuthContext';
import { toastSuccess } from '../../../utils/toastManager';
import { formButtons } from '../formComponents';

function User({ id }) {
  const { auth } = useAuthContext();

  // form inputs
  const [formEmail, setFormEmail] = useState('');
  const [formCreatedDate, setFormCreatedDate] = useState(
    !id ? new Date().toJSON().slice(0, 10) : null,
  );

  async function fetchRecords() {
    if (!auth || !auth.currentUser || !id) return;

    // fetch records
    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [id],
          collectionName: 'users',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setFormEmail(response.records[0]?.email || '');
        setFormCreatedDate(response.records[0]?.createdDate);
      });
  }
  useEffect(() => {
    fetchRecords();
  }, [auth?.currentUser, id]);

  function handleSave(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'UPDATE_ONE',
        params: {
          id,
          valuesObj: {
            email: formEmail,
            createdDate: formCreatedDate,
          },
          collectionName: 'users',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Success!');
      });
  }

  function handleDelete(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'DELETE_ONE',
        params: {
          id,
          collectionName: 'users',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Deleted!');
      });
  }

  function detailsForm() {
    return (
      <form className="bg-color-2 px-4 py-2 text-white">
        <div className="grid grid-cols-[1fr,2fr] pt-4">
          <label className="mb-2 mr-2 text-right text-gray-300" htmlFor="id">
            ID:
          </label>
          <input
            className="mb-2 rounded bg-color-2 outline-none"
            type="text"
            id="id"
            name="id"
            defaultValue={id}
            readOnly
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="createdDate"
          >
            Email:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="email"
            id="email"
            name="email"
            defaultValue={formEmail}
            onChange={(e) => setFormEmail(e.target.value)}
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="createdDate"
          >
            Created Date:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="date"
            id="createdDate"
            name="createdDate"
            defaultValue={formCreatedDate}
            onChange={(e) => setFormCreatedDate(e.target.value)}
          />
        </div>
        {formButtons(id, handleDelete, handleSave)}
      </form>
    );
  }

  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] overflow-y-scroll rounded border-4 border-color-4 bg-white pt-4
      `}
    >
      <h1 className="px-4">User Details</h1>
      {detailsForm()}
    </div>
  );
}

export default User;
