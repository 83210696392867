import React from 'react';

function CourseSelectionPopup({
  courses,
  formCourseId,
  setFormCourseId,
  setOpenCourseIdPopup,
}) {
  function selectionButton(id, text) {
    return (
      <button
        className={`
          ${formCourseId === id ? 'bg-color-1' : ''}

          border-b-2 border-b-color-1

          hover:text-color-5
        `}
        type="button"
        onClick={() => {
          setFormCourseId(id);
          setOpenCourseIdPopup(false);
        }}
      >
        {text}
      </button>
    );
  }
  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] max-w-[95vw] overflow-y-scroll rounded border-4 border-color-4
        bg-white text-white
      `}
    >
      <div className="sticky top-0">
        <h1 className="bg-white px-4 pt-4">Select Course</h1>
        <div className="grid grid-cols-2 bg-color-1 text-center">
          <div>ID</div>
          <div>Title</div>
        </div>
      </div>
      <div className="grid grid-cols-2 bg-color-2 text-center">
        {courses?.map((c) => (
          <>
            {selectionButton(c._id, c._id)}
            {selectionButton(c._id, c.title)}
          </>
        ))}
      </div>
    </div>
  );
}

export default CourseSelectionPopup;
