import React from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import BarLoader from 'react-spinners/BarLoader';
import { toastError } from '../../utils/toastManager';

function processTransaction(course, auth, promotion, setProcessingOrder) {
  setProcessingOrder(true);
  fetch('/.netlify/functions/transactions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: auth.currentUser.email,
      payload: auth.currentUser.uid,
      courseId: course._id,
      amount: promotion ? promotion.cost : course.cost,
      promotion: promotion ? promotion._id : '',
      duration: {
        type: course.duration.type,
        value: course.duration.value,
      },
    }),
  })
    .then((response) => response.json())
    .then((responseJSON) => {
      setProcessingOrder(false);
      if (responseJSON.message === 'success') {
        window.location.reload(false);
      }
    });
}

function Checkout({
  course,
  auth,
  promotion,
  setProcessingOrder, //
}) {
  const [{ isPending }] = usePayPalScriptReducer();

  const onCreateOrder = (data, actions) => {
    if (!course) return null;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: promotion ? promotion.cost : course.cost,
          },
        },
      ],
      application_context: {
        shipping_preference: 'NO_SHIPPING',
      },
    });
  };

  const onApproveOrder = (data, actions) =>
    actions.order
      .capture()
      .then(() => {
        processTransaction(course, auth, promotion, setProcessingOrder);
      })
      .catch(() => {
        toastError('Oops, please try again.');
      });

  return (
    <div className="text-white">
      {isPending ? (
        <div className="flex justify-center">
          <BarLoader color="#023059" className="m-4" loading={isPending} />
        </div>
      ) : (
        <PayPalButtons
          style={{
            color: 'blue',
            shape: 'rect',
            label: 'pay',
            tagline: false,
            layout: 'vertical',
          }}
          createOrder={onCreateOrder}
          onApprove={onApproveOrder}
          onCancel={() => {
            toastError('Canceled');
          }}
          onError={() => {
            toastError('Oops, please try again.');
          }}
        />
      )}
    </div>
  );
}

export { Checkout, processTransaction };
