import React from 'react';
import { Link, Outlet, ScrollRestoration } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Nav from './Nav';
import Footer from './Footer';

function Layout({ outlet }) {
  return (
    <div className="flex min-h-screen flex-col">
      <Nav />
      {/* Outlet wrapper auto fills to full screen height */}
      <div className="flex flex-auto flex-col pt-24">
        {outlet || <Outlet />}
      </div>
      <Footer />
      <ScrollRestoration getKey={(location) => location.pathname} />
      <CookieConsent
        style={{ background: '#023059' }}
        buttonStyle={{
          borderRadius: '5px',
          background: '#ffffff',
          color: '#023059',
          fontSize: '13px',
        }}
      >
        This website uses cookies to enhance your experience.
        <p style={{ fontSize: '10px' }}>
          For more information, see our&nbsp;
          <Link to="cookies">Cookie Policy</Link>
        </p>
      </CookieConsent>
    </div>
  );
}

export default Layout;
