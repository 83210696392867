import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { contentHTMLGenerator } from '../../utils/componentGeneration';
import { useAuthContext } from '../../utils/AuthContext';

function Lesson() {
  const { auth } = useAuthContext();
  const { courseId, lessonId } = useParams();
  const [lesson, setLesson] = useState();
  const [lastLesson, setLastLesson] = useState();
  const [nextLesson, setNextLesson] = useState();
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    if (!auth) return;
    let authQuery = '';
    if (auth.currentUser) {
      authQuery = `&email=${auth.currentUser.email}&payload=${auth.currentUser.uid}`;
    }

    setLoading(true);

    await fetch(
      `/.netlify/functions/lessons?course=${courseId}&ids=${lessonId}${authQuery}`,
    )
      .then((response) => response.json())
      .then((lessonData) => {
        if (lessonData.message !== 'Invalid Credentials') {
          setLesson(lessonData[0]);
        }
      });
    await fetch(`/.netlify/functions/courses?ids=${courseId}`)
      .then((response) => response.json())
      .then((courseData) => {
        const course = courseData[0];
        course.lessons.forEach((id, index) => {
          if (id === lessonId && index === 0) {
            setLastLesson();
          } else if (id === lessonId && index > 0) {
            fetch(
              `/.netlify/functions/lessons?course=${courseId}&ids=${
                course.lessons[index - 1]
              }${authQuery}`,
            )
              .then((response) => response.json())
              .then((lessonData) => {
                if (lessonData.message !== 'Invalid Credentials') {
                  setLastLesson(lessonData[0]);
                }
              });
          }
          if (id === lessonId && index === course.lessons.length - 1) {
            setNextLesson();
          } else if (id === lessonId && index < course.lessons.length - 1) {
            fetch(
              `/.netlify/functions/lessons?course=${courseId}&ids=${
                course.lessons[index + 1]
              }${authQuery}`,
            )
              .then((response) => response.json())
              .then((lessonData) => {
                if (lessonData.message !== 'Invalid Credentials') {
                  setNextLesson(lessonData[0]);
                }
              });
          }
        });
      });

    setLoading(false);
  }
  useEffect(() => {
    fetchData();
  }, [auth?.currentUser, lessonId]);
  useEffect(() => {
    document.title = lesson?.title || 'Lesson';
  }, [lesson]);

  return (
    <div>
      <div className="flex justify-center">
        <BarLoader
          color="#023059"
          className="m-4"
          loading={!lesson || loading}
        />
      </div>
      <h1 className="mb-2 mt-4 w-full text-center text-3xl">{lesson?.title}</h1>
      <div className="flex flex-wrap justify-between text-color-1">
        {lastLesson ? (
          <Link
            to={`/lessons/${courseId}/${lastLesson?._id}`}
            className={`
              m-2 text-nowrap transition-all ease-in-out

              hover:text-color-5
            `}
          >
            {`Last: ${lastLesson?.title}`}
          </Link>
        ) : (
          <div />
        )}
        {nextLesson ? (
          <Link
            to={`/lessons/${courseId}/${nextLesson?._id}`}
            className={`
              m-2 text-nowrap transition-all ease-in-out

              hover:text-color-5
            `}
          >
            {`Next: ${nextLesson?.title}`}
          </Link>
        ) : (
          <div />
        )}
      </div>
      {contentHTMLGenerator(lesson?.content)}
    </div>
  );
}

export default Lesson;
