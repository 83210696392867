import React from 'react';

function LessonSelectionPopup({
  lessons,
  formLessons,
  setFormLessons,
  setOpenLessonsPopup,
  index, //
}) {
  function selectionButton(id, text) {
    return (
      <button
        className={`
          ${formLessons[index] === id ? 'bg-color-1' : ''}

          border-b-2 border-b-color-1

          hover:text-color-5
        `}
        type="button"
        onClick={() => {
          setFormLessons((prev) => {
            const newPrev = [...prev];
            newPrev[index] = id;
            return newPrev;
          });
          setOpenLessonsPopup((prev) => ({
            ...prev,
            [index]: false,
          }));
        }}
      >
        {text}
      </button>
    );
  }
  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] max-w-[95vw] overflow-y-scroll rounded border-4 border-color-4
        bg-white text-white
      `}
    >
      <div className="sticky top-0">
        <h1 className="bg-white px-4 pt-4">Select Lesson</h1>
        <div className="grid grid-cols-[4fr,4fr,1fr] bg-color-1 text-center">
          <div>ID</div>
          <div>Title</div>
          <div>Free</div>
        </div>
      </div>
      <div className="grid grid-cols-[4fr,4fr,1fr] bg-color-2 text-center">
        {lessons?.map((l) => (
          <>
            {selectionButton(l._id, l._id)}
            {selectionButton(l._id, l.title)}
            {selectionButton(l._id, l.free ? 'Free' : '')}
          </>
        ))}
      </div>
    </div>
  );
}

export default LessonSelectionPopup;
