import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { contentHTMLGenerator } from '../../utils/componentGeneration';
import SocialMediaButtons from '../../utils/SocialMediaButtons';

function Post() {
  const { postId } = useParams();
  const [post, setPost] = useState();

  async function fetchData() {
    await fetch(`/.netlify/functions/posts?ids=${postId}`)
      .then((response) => response.json())
      .then((response) => {
        if (response.length > 0) setPost(response[0]);
      });
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    document.title = post?.title;
  }, [post]);

  return (
    <div>
      <div className="flex justify-center">
        <BarLoader color="#023059" className="m-4" loading={!post} />
      </div>
      <h1 className="mb-2 mt-4 w-full text-center text-3xl">{post?.title}</h1>
      <div className="w-full bg-color-1 py-2 text-white">
        <div className="mx-auto flex max-w-2xl justify-end gap-1 px-2">
          <SocialMediaButtons title={post?.title} />
        </div>
      </div>
      {contentHTMLGenerator(post?.content)}
    </div>
  );
}

export default Post;
