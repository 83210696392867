import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useParams } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { cardContainerHTMLGenerator } from '../../utils/componentGeneration';
import SocialMediaButtons from '../../utils/SocialMediaButtons';

function Instructor() {
  const { instructorId } = useParams();
  const [instructor, setInstructor] = useState();
  const [courses, setCourses] = useState();

  async function fetchData() {
    // fetch instructor
    await fetch(`/.netlify/functions/instructors?ids=${instructorId}`)
      .then((response) => response.json())
      .then(async (responseInstructor) => {
        if (responseInstructor.length > 0) {
          setInstructor(responseInstructor[0]);
          // fetch courses
          await fetch(
            `/.netlify/functions/courses?instructor=${responseInstructor[0]._id}&fields=title&fields=coverImageURL`,
          )
            .then((response) => response.json())
            .then((response) => setCourses(response));
        }
      });
  }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    document.title = instructor?.displayName;
  }, [instructor]);

  if (!instructor) {
    return (
      <div className="flex justify-center">
        <BarLoader color="#023059" className="m-4" loading={!instructor} />
      </div>
    );
  }
  return (
    <div className="flex flex-col flex-wrap items-center">
      <h1 className="mt-4 text-center text-3xl">{instructor?.displayName}</h1>

      {/* SOCIAL MEDIA SECTION */}
      <div className="w-full bg-color-1 py-2 text-white">
        <div className="mx-auto flex max-w-2xl justify-end gap-1 px-2">
          <SocialMediaButtons />
        </div>
      </div>

      {/* IMAGE SECTION */}
      {instructor?.imageURLs?.length > 0 && (
        <div className="flex w-full justify-center bg-color-4 p-4 shadow-section">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            dynamicHeight
            className="m-4 max-w-96 overflow-hidden rounded border-2 border-white"
          >
            {instructor?.imageURLs.map((url) => (
              <div>
                <img
                  className="h-96 object-cover"
                  src={`${url}/m/400x400`}
                  alt="slideshow"
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}

      {/* BIO SECTION */}
      {instructor?.bio?.map((text) => (
        <div className="w-full bg-color-2 py-4 text-white">
          <p className="mx-auto max-w-2xl px-2">{text}</p>
        </div>
      ))}

      {/* CERT IMAGE SECTION */}
      {instructor?.certImageURLs?.length > 0 && (
        <div className="flex w-full justify-center bg-color-4 p-4 shadow-section">
          <Carousel
            showThumbs={false}
            showStatus={false}
            infiniteLoop
            autoPlay
            dynamicHeight
            className="m-4 max-w-96 overflow-hidden rounded border-2 border-white bg-color-1"
          >
            {instructor?.certImageURLs.map((url) => (
              <div>
                <img
                  className="h-96 object-scale-down"
                  src={`${url}/m/0x400`}
                  alt="slideshow"
                />
              </div>
            ))}
          </Carousel>
        </div>
      )}

      {/* COURSE SECTION */}
      <div className="flex justify-center">
        <BarLoader color="#023059" className="m-4" loading={!courses} />
      </div>
      {courses?.length > 0 && (
        <>
          <h1 className="my-4 text-center text-3xl">Courses</h1>
          {cardContainerHTMLGenerator(
            courses?.map((course) => ({
              _id: course._id,
              coverImageURL: course.coverImageURL,
              slug: '/courses/',
              text_1: course.title,
            })),
          )}
        </>
      )}
    </div>
  );
}

export default Instructor;
