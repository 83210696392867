import React from 'react';

function CoursesSelectionPopup({
  courses,
  formCourseIds,
  setFormCourseIds,
  setOpenCoursesPopup,
  index, //
}) {
  function selectionButton(id, text) {
    return (
      <button
        className={`
          ${formCourseIds[index] === id ? 'bg-color-1' : ''}

          border-b-2 border-b-color-1

          hover:text-color-5
        `}
        type="button"
        onClick={() => {
          setFormCourseIds((prev) => {
            const newPrev = [...prev];
            newPrev[index] = id;
            return newPrev;
          });
          setOpenCoursesPopup((prev) => ({
            ...prev,
            [index]: false,
          }));
        }}
      >
        {text}
      </button>
    );
  }

  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] max-w-[95vw] overflow-y-scroll rounded border-4 border-color-4
        bg-white text-white
      `}
    >
      <div className="sticky top-0">
        <h1 className="bg-white px-4 pt-4">Select Instructor</h1>
        <div className="grid grid-cols-2 bg-color-1 text-center">
          <div>ID</div>
          <div>Name</div>
        </div>
      </div>
      <div className="grid grid-cols-2 bg-color-2 text-center">
        {courses?.map((i) => (
          <>
            {selectionButton(i._id, i._id)}
            {selectionButton(i._id, i.title)}
          </>
        ))}
      </div>
    </div>
  );
}

export default CoursesSelectionPopup;
