import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useAuthContext } from '../../../utils/AuthContext';
import LessonSelectionPopup from '../../../utils/selectionPopups/LessonSelectionPopup';
import InstructorSelectionPopup from '../../../utils/selectionPopups/InstructorSelectionPopup';
import { toastSuccess } from '../../../utils/toastManager';
import {
  formButtons,
  formContentForm,
  formDurationForm,
} from '../formComponents';

function Course({ id }) {
  const { auth } = useAuthContext();

  // form inputs
  const [formTitle, setFormTitle] = useState('');
  const [formCoverImage, setFormCoverImage] = useState('');
  const [formContent, setFormContent] = useState([]);
  const [formLanguage, setFormLanguage] = useState('');
  const [formInstructors, setFormInstructors] = useState([]);
  const [formLessons, setFormLessons] = useState([]);
  const [formCost, setFormCost] = useState(0);
  const [formDraft, setFormDraft] = useState(false);

  const [lessons, setLessons] = useState();
  const [instructors, setInstructors] = useState();

  const [openLessonsPopup, setOpenLessonsPopup] = useState({});
  const [openInstructorsPopup, setOpenInstructorsPopup] = useState({});
  const [formDuration, setFormDuration] = useState({ type: 'year', value: 1 });

  async function fetchRecords() {
    if (!auth || !auth.currentUser) return;

    // fetch records
    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [id],
          collectionName: 'courses',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setFormTitle(response.records[0]?.title || '');
        setFormDraft(response.records[0]?.draft || false);
        setFormContent(response.records[0]?.content || []);
        setFormCoverImage(response.records[0]?.coverImageURL || '');
        setFormLanguage(response.records[0]?.language || '');
        setFormInstructors(response.records[0]?.instructors || []);
        setFormLessons(response.records[0]?.lessons || []);
        setFormCost(response.records[0]?.cost || 0);
        setFormDuration(
          response.records[0]?.duration || { type: 'year', value: 1 },
        );
      });

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [],
          orderBy: '_id',
          asc: 1,
          fields: ['_id', 'title', 'free'],
          collectionName: 'lessons',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setLessons(response.records);
      });

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [],
          orderBy: 'displayName',
          asc: 1,
          fields: ['_id', 'displayName'],
          collectionName: 'instructors',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setInstructors(response.records);
      });
  }

  useEffect(() => {
    fetchRecords();
  }, [auth?.currentUser, id]);

  function handleSave(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'UPDATE_ONE',
        params: {
          id,
          valuesObj: {
            draft: formDraft,
            title: formTitle,
            content: formContent,
            coverImageURL: formCoverImage,
            language: formLanguage,
            instructors: formInstructors,
            lessons: formLessons,
            cost: parseFloat(formCost),
            duration: {
              type: formDuration.type,
              value: parseFloat(formDuration.value),
            },
          },
          collectionName: 'courses',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Success!');
      });
  }

  function handleDelete(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'DELETE_ONE',
        params: {
          id,
          collectionName: 'courses',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Deleted!');
      });
  }

  function detailsForm() {
    return (
      <form className="bg-color-2 px-4 py-2 text-white">
        <div className="grid grid-cols-[1fr,2fr] pt-4">
          <label className="mb-2 mr-2 text-right text-gray-300" htmlFor="id">
            ID:
          </label>
          <input
            className="mb-2 rounded bg-color-2 outline-none"
            type="text"
            id="id"
            name="id"
            defaultValue={id}
            readOnly
          />
          <label className="mr-2 text-right text-gray-300" htmlFor="formTitle">
            Title:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="string"
            id="formTitle"
            name="formTitle"
            defaultValue={formTitle}
            onChange={(e) => setFormTitle(e.target.value)}
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formCoverImage"
          >
            Cover Image URL:
          </label>
          <div className="flex flex-col">
            <input
              className={`
                mb-2 rounded bg-color-1 px-1

                focus:outline-none focus:ring-2 focus:ring-color-5
              `}
              type="string"
              id="formCoverImage"
              name="formCoverImage"
              defaultValue={formCoverImage}
              onChange={(e) => setFormCoverImage(e.target.value)}
            />
            {formCoverImage && (
              <img
                className="mb-2 w-[200px] rounded"
                alt="formCoverImageURL"
                src={`${formCoverImage}/m/200x0`}
              />
            )}
          </div>
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formLanguage"
          >
            Language:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="string"
            id="formLanguage"
            name="formLanguage"
            defaultValue={formLanguage}
            onChange={(e) => setFormLanguage(e.target.value)}
          />
          <label className="mr-2 text-right text-gray-300" htmlFor="formCost">
            Cost:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="number"
            id="formCost"
            name="formCost"
            value={formCost /* input value not updating with defaultValue */}
            onChange={(e) => setFormCost(e.target.value)}
          />
          {formDurationForm(formDuration, setFormDuration)}
          <label className="mr-2 text-right text-gray-300" htmlFor="formDraft">
            Draft:
          </label>
          <div className="flex items-center justify-start">
            <input
              className={`
                rounded bg-color-1 px-1

                focus:outline-none focus:ring-2 focus:ring-color-5
              `}
              checked={formDraft}
              type="checkbox"
              id="formDraft"
              name="formDraft"
              onChange={(e) => setFormDraft(e.target.checked)}
            />
          </div>

          {/* CONTENT */}
          {formContentForm(formContent, setFormContent)}
          {/* END CONTENT */}

          {/* LESSONS */}
          <div className="flex flex-col items-end">
            <label className="mr-2 text-right text-gray-300">Lessons:</label>
            <button
              type="button"
              className={`
                mr-2 max-w-max rounded border-2 border-color-1 px-1 bg-color-white transition-all
                ease-in-out

                active:opacity-50

                hover:border-color-5 hover:bg-white hover:text-color-1
              `}
              onClick={() => setFormLessons((prev) => prev.concat(''))}
            >
              Add
            </button>
          </div>
          <div className="flex flex-col">
            {formLessons?.map((lesson, index) => (
              <div className="flex flex-col gap-1">
                <div className="grid grid-cols-[9fr,2rem]">
                  <button
                    onClick={
                      () =>
                        setOpenLessonsPopup((prev) => ({
                          ...prev,
                          [index]: true,
                        }))
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    className={`
                      mb-2 h-6 rounded bg-color-1 px-1 text-left

                      focus:outline-none focus:ring-2 focus:ring-color-5
                    `}
                    type="button"
                  >
                    {lessons?.filter((l) => l._id === lesson)[0]?.title}
                  </button>
                  <Popup modal open={openLessonsPopup[index]} nested>
                    <LessonSelectionPopup
                      lessons={lessons}
                      formLessons={formLessons}
                      setFormLessons={setFormLessons}
                      index={index}
                      setOpenLessonsPopup={setOpenLessonsPopup}
                    />
                  </Popup>

                  <button
                    type="button"
                    className={`
                      ml-1 h-6 rounded border-2 border-color-1 px-1 bg-color-white transition-all
                      ease-in-out

                      active:opacity-50

                      hover:border-color-5 hover:bg-color-4 hover:text-white
                    `}
                    onClick={
                      () =>
                        setFormLessons((prev) =>
                          prev.filter((_, i) => i !== index),
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* END LESSONS */}

          {/* INSTRUCTORS */}
          <div className="flex flex-col items-end">
            <label className="mr-2 text-right text-gray-300">
              Instructors:
            </label>
            <button
              type="button"
              className={`
                mr-2 max-w-max rounded border-2 border-color-1 px-1 bg-color-white transition-all
                ease-in-out

                active:opacity-50

                hover:border-color-5 hover:bg-white hover:text-color-1
              `}
              onClick={() => setFormInstructors((prev) => prev.concat(''))}
            >
              Add
            </button>
          </div>
          <div className="flex flex-col">
            {formInstructors?.map((instructor, index) => (
              <div className="flex flex-col gap-1">
                <div className="grid grid-cols-[9fr,2rem]">
                  <button
                    onClick={
                      () =>
                        setOpenInstructorsPopup((prev) => ({
                          ...prev,
                          [index]: true,
                        }))
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    className={`
                      mb-2 h-6 rounded bg-color-1 px-1 text-left

                      focus:outline-none focus:ring-2 focus:ring-color-5
                    `}
                    type="button"
                  >
                    {
                      instructors?.filter((l) => l._id === instructor)[0]
                        ?.displayName
                    }
                  </button>
                  <Popup nested open={openInstructorsPopup[index]}>
                    <InstructorSelectionPopup
                      instructors={instructors}
                      formInstructors={formInstructors}
                      setFormInstructors={setFormInstructors}
                      setOpenInstructorsPopup={setOpenInstructorsPopup}
                      index={index}
                    />
                  </Popup>
                  <button
                    type="button"
                    className={`
                      ml-1 h-6 rounded border-2 border-color-1 px-1 bg-color-white transition-all
                      ease-in-out

                      active:opacity-50

                      hover:border-color-5 hover:bg-color-4 hover:text-white
                    `}
                    onClick={
                      () =>
                        setFormInstructors((prev) =>
                          prev.filter((_, i) => i !== index),
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    -
                  </button>
                </div>
              </div>
            ))}
          </div>
          {/* END INSTRUCTORS */}
        </div>
        {formButtons(id, handleDelete, handleSave)}
      </form>
    );
  }

  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] overflow-y-scroll rounded border-4 border-color-4 bg-white pt-4
      `}
    >
      <h1 className="px-4">Course Details</h1>
      {detailsForm()}
    </div>
  );
}

export default Course;
