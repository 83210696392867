import React, { useEffect, useState } from 'react';
import { signOut } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import BarLoader from 'react-spinners/BarLoader';
import { useAuthContext } from '../../utils/AuthContext';
import AuthComponent from './AuthComponent';
import {
  dateToText,
  getTransactionExpiryDate,
  transactionActive,
} from '../../utils/dataManip';
import { cardContainerHTMLGenerator } from '../../utils/componentGeneration';
import { toastError, toastSuccess } from '../../utils/toastManager';

function Account() {
  const { auth } = useAuthContext();
  const [user, setUser] = useState();
  const [transactions, setTransactions] = useState();
  const [allCourses, setAllCourses] = useState([]);
  const [activeCourses, setActiveCourses] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Account';
  }, []);
  async function fetchUserData() {
    if (!auth || !auth.currentUser) return;

    // fetch user
    await fetch(
      `/.netlify/functions/auth?email=${auth.currentUser.email}&payload=${auth.currentUser.uid}`,
    )
      .then((response) => response.json())
      .then((response) => setUser(response));

    // fetch transactions
    const courseIds = [];
    const activeCourseIds = [];
    await fetch(
      `/.netlify/functions/transactions?email=${auth.currentUser.email}&payload=${auth.currentUser.uid}`,
    ).then(async (response) => {
      const transactionData = await response.json();
      if (transactionData.message === 'Invalid Credentials') return;
      setTransactions(transactionData);
      transactionData.forEach((transaction) => {
        if (!courseIds.includes(transaction.courseId)) {
          courseIds.push(transaction.courseId);
        }
        if (
          // eslint-disable-next-line operator-linebreak
          !activeCourseIds.includes(transaction.courseId) &&
          transactionActive(transaction)
        ) {
          activeCourseIds.push(transaction.courseId);
        }
      });
    });

    // fetch courses
    if (courseIds.length > 0) {
      await fetch(
        `/.netlify/functions/courses?fields=title&fields=coverImageURL${courseIds
          .map((id) => `&ids=${id}`)
          .join('')}`,
      ).then(async (response) => {
        const courseData = await response.json();
        setAllCourses(courseData);
        setActiveCourses(
          courseData.filter((course) => activeCourseIds.includes(course._id)),
        );
      });
    }
  }
  useEffect(() => {
    fetchUserData();
  }, [auth?.currentUser]);

  function setUserReceivePromotionalEmails(checked) {
    fetch('/.netlify/functions/auth', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        _id: user._id,
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        receivePromotionalEmails: checked,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') {
          toastSuccess(checked ? 'Subscribed!' : 'Unsubscribed!');
        } else {
          toastError('Uh oh, please try again');
        }
      });
  }

  function coursesComponent() {
    if (!transactions) {
      return (
        <div
          className={`
            flex flex-wrap justify-center gap-4 bg-color-4 px-[10%] py-8 font-ia text-white
            shadow-section
          `}
        >
          <BarLoader color="#F2A516" className="m-4" loading />
        </div>
      );
    }
    if (activeCourses.length === 0) {
      return (
        <div
          className={`
            flex flex-col flex-wrap justify-center gap-4 bg-color-4 px-[10%] py-8 font-ia text-white
            shadow-section
          `}
        >
          No Courses Active
        </div>
      );
    }
    return cardContainerHTMLGenerator(
      activeCourses.map((course) => ({
        _id: course._id,
        coverImageURL: course.coverImageURL,
        slug: '/courses/',
        text_1: course.title,
      })),
    );
  }

  function transactionsComponent() {
    if (!transactions) {
      return (
        <div
          className={`
            flex flex-wrap justify-center gap-4 bg-color-4 px-[10%] py-8 font-ia text-white
            shadow-section
          `}
        >
          <BarLoader color="#F2A516" className="m-4" loading />
        </div>
      );
    }
    if (transactions?.length === 0 || allCourses?.length === 0) {
      return (
        <div
          className={`
            flex flex-col flex-wrap justify-center gap-1 bg-color-4 px-[10%] py-8 font-ia text-white
            shadow-section
          `}
        >
          <div>No Transactions</div>
        </div>
      );
    }
    return (
      <div
        className={`
          flex flex-col flex-wrap justify-center gap-1 bg-color-4 px-[10%] py-8 font-ia text-white
          shadow-section
        `}
      >
        {transactions
          .sort((a, b) => (a.dateTime < b.dateTime ? 1 : -1)) // show newest first
          .map((transaction) => {
            const courseTitle = allCourses
              .filter((course) => course._id === transaction.courseId)
              .map((course) => course.title);
            const date = dateToText(new Date(transaction.dateTime));
            const expiryDate = dateToText(
              getTransactionExpiryDate(transaction),
            );
            const active = transactionActive(transaction);
            return (
              <div
                className={`
                  flex w-full justify-between rounded p-2

                  max-sm:flex-col

                  ${
                    active
                      ? 'bg-color-2 text-white'
                      : 'bg-color-1 text-gray-300'
                  }
                `}
              >
                <div>{courseTitle}</div>
                <div className="font-sans">{`${date} ~ ${expiryDate}`}</div>
              </div>
            );
          })}
      </div>
    );
  }

  function accountComponent() {
    if (!user) {
      return (
        <div className="flex justify-center">
          <BarLoader color="#023059" className="m-4" loading />
        </div>
      );
    }
    return (
      <div>
        {/* USER DETAILS SECTION */}
        <h1 className="mb-2 mt-4 w-full text-center text-3xl">Account</h1>
        <div
          className={`
            flex items-center space-between justify-center gap-4 bg-color-2 px-[10%] py-8 font-ia
            text-white
          `}
        >
          <div className="flex w-full flex-col flex-wrap items-start justify-center gap-4">
            <div>{`Email: ${auth.currentUser.email}`}</div>
            <div>
              <input
                type="checkbox"
                id="promotionalEmailCheckbox"
                name="promotionalEmailCheckbox"
                checked={user?.receivePromotionalEmails}
                onChange={(e) => {
                  setUserReceivePromotionalEmails(e.target.checked);
                }}
              />
              <label className="m-2" htmlFor="promotionalEmailCheckbox">
                Receive Promotional Emails
              </label>
            </div>
            <button
              className={`
                w-24 rounded bg-color-1 p-2 transition-all ease-in-out

                hover:-translate-y-0.5 hover:shadow-card
              `}
              type="button"
              onClick={() => {
                signOut(auth);
                navigate(0);
              }}
            >
              Logout
            </button>
          </div>

          {process.env.REACT_APP_ADMIN_UIDS.split(',').includes(
            auth.currentUser.uid,
          ) && (
            <Link
              className={`
                w-24 rounded bg-color-1 p-2 text-center transition-all ease-in-out

                hover:-translate-y-0.5 hover:shadow-card
              `}
              to="/admin"
            >
              Admin Panel
            </Link>
          )}
        </div>

        {/* ACTIVE COURSES SECTION */}
        <h1 className="mb-2 mt-4 w-full text-center text-3xl">Courses</h1>
        {coursesComponent()}

        {/* TRANSACTION HISTORY SECTION */}
        <h1 className="mb-2 mt-4 w-full text-center text-3xl">Transactions</h1>
        {transactionsComponent()}
      </div>
    );
  }

  if (auth && auth.currentUser) {
    return accountComponent();
  }
  if (auth && !auth.currentUser) {
    return <AuthComponent />;
  }
}

export default Account;
