import React, { useEffect, useState } from 'react';
import { useAuthContext } from '../../../utils/AuthContext';
import { toastSuccess } from '../../../utils/toastManager';
import { formButtons } from '../formComponents';

function Instructor({ id }) {
  const { auth } = useAuthContext();

  // form inputs
  const [formCoverImageURL, setFormCoverImageURL] = useState('');
  const [formDisplayName, setFormDisplayName] = useState('');
  const [formSubtitle, setFormSubtitle] = useState('');
  const [formBio, setFormBio] = useState('');
  const [formCertImageURLS, setFormCertImageURLS] = useState([]);
  const [formImageURLS, setFormImageURLS] = useState([]);

  async function fetchRecords() {
    if (!auth || !auth.currentUser) return;

    // fetch records
    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'FIND',
        params: {
          ids: [id],
          collectionName: 'instructors',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setFormCoverImageURL(response.records[0]?.coverImageURL || '');
        setFormDisplayName(response.records[0]?.displayName || '');
        setFormSubtitle(response.records[0]?.subtitle || '');
        setFormBio(response.records[0]?.bio.join('\n\n') || '');
        setFormCertImageURLS(response.records[0]?.certImageURLs || []);
        setFormImageURLS(response.records[0]?.imageURLs || []);
      });
  }
  useEffect(() => {
    fetchRecords();
  }, [auth?.currentUser, id]);

  function handleSave(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'UPDATE_ONE',
        params: {
          id,
          valuesObj: {
            coverImageURL: formCoverImageURL,
            displayName: formDisplayName,
            subtitle: formSubtitle,
            bio: formBio.split('\n\n'),
            certImageURLs: formCertImageURLS,
            imageURLs: formImageURLS,
          },
          collectionName: 'instructors',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Success!');
      });
  }

  function handleDelete(e) {
    e.preventDefault();

    fetch('/.netlify/functions/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: auth.currentUser.email,
        payload: auth.currentUser.uid,
        route: 'DELETE_ONE',
        params: {
          id,
          collectionName: 'instructors',
        },
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.message === 'success') toastSuccess('Deleted!');
      });
  }

  function detailsForm() {
    return (
      <form className="bg-color-2 px-4 py-2 text-white">
        <div className="grid grid-cols-[1fr,2fr] pt-4">
          <label className="mb-2 mr-2 text-right text-gray-300" htmlFor="id">
            ID:
          </label>
          <input
            className="mb-2 rounded bg-color-2 outline-none"
            type="text"
            id="id"
            name="id"
            defaultValue={id}
            readOnly
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formCoverImageURL"
          >
            Cover Image URL:
          </label>
          <div className="flex flex-col">
            <input
              className={`
                mb-2 rounded bg-color-1 px-1

                focus:outline-none focus:ring-2 focus:ring-color-5
              `}
              type="string"
              id="formCoverImageURL"
              name="formCoverImageURL"
              defaultValue={formCoverImageURL}
              onChange={(e) => setFormCoverImageURL(e.target.value)}
            />
            {formCoverImageURL && (
              <img
                className="mb-2 w-[200px] rounded"
                alt="formCoverImageURL"
                src={`${formCoverImageURL}/m/200x0`}
              />
            )}
          </div>
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formDisplayName"
          >
            Display Name:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="string"
            id="formDisplayName"
            name="formDisplayName"
            defaultValue={formDisplayName}
            onChange={(e) => setFormDisplayName(e.target.value)}
          />
          <label
            className="mr-2 text-right text-gray-300"
            htmlFor="formSubtitle"
          >
            Subtitle:
          </label>
          <input
            className={`
              mb-2 rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            type="string"
            id="formSubtitle"
            name="formSubtitle"
            defaultValue={formSubtitle}
            onChange={(e) => setFormSubtitle(e.target.value)}
          />
          <label className="mr-2 text-right text-gray-300" htmlFor="formBio">
            Bio:
          </label>
          <textarea
            className={`
              mb-2 resize rounded bg-color-1 px-1

              focus:outline-none focus:ring-2 focus:ring-color-5
            `}
            id="formBio"
            name="formBio"
            defaultValue={formBio}
            onChange={(e) => setFormBio(e.target.value)}
          />

          {/* CERT IMAGE URLS */}
          <div className="flex flex-col items-end">
            <label className="mr-2 text-right text-gray-300">
              Cert Image URLS:
            </label>
            <button
              type="button"
              className={`
                mr-2 max-w-max rounded border-2 border-color-1 px-1 bg-color-white transition-all
                ease-in-out

                active:opacity-50

                hover:border-color-5 hover:bg-white hover:text-color-1
              `}
              onClick={() => setFormCertImageURLS((prev) => prev.concat(''))}
            >
              Add
            </button>
          </div>
          <div className="flex flex-col">
            {formCertImageURLS?.map((url, index) => (
              <div className="flex flex-col">
                <div className="grid grid-cols-[9fr,2rem]">
                  <input
                    className={`
                      mb-2 rounded bg-color-1 px-1

                      focus:outline-none focus:ring-2 focus:ring-color-5
                    `}
                    id="formCertImageURLS"
                    name="formCertImageURLS"
                    defaultValue={url}
                    onChange={
                      (e) =>
                        setFormCertImageURLS((prev) =>
                          prev.map((u, i) =>
                            i === index ? e.target.value : u,
                          ),
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  />
                  <button
                    type="button"
                    className={`
                      ml-1 h-6 rounded border-2 border-color-1 px-1 bg-color-white transition-all
                      ease-in-out

                      active:opacity-50

                      hover:border-color-5 hover:bg-color-4 hover:text-white
                    `}
                    onClick={
                      () =>
                        setFormCertImageURLS((prev) =>
                          prev.filter((_, i) => i !== index),
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    -
                  </button>
                </div>
                {url !== '' && (
                  <img
                    className="mb-2 w-[200px] rounded"
                    alt="certImageUrl"
                    src={`${url}/m/200x0`}
                  />
                )}
              </div>
            ))}
          </div>
          {/* END CERT IMAGE URLS */}

          {/* IMAGE URLS */}
          <div className="flex flex-col items-end">
            <label className="mr-2 text-right text-gray-300">Image URLS:</label>
            <button
              type="button"
              className={`
                mr-2 max-w-max rounded border-2 border-color-1 px-1 bg-color-white transition-all
                ease-in-out

                active:opacity-50

                hover:border-color-5 hover:bg-white hover:text-color-1
              `}
              onClick={() => setFormImageURLS((prev) => prev.concat(''))}
            >
              Add
            </button>
          </div>
          <div className="flex flex-col">
            {formImageURLS?.map((url, index) => (
              <div className="flex flex-col">
                <div className="grid grid-cols-[9fr,2rem]">
                  <input
                    className={`
                      mb-2 rounded bg-color-1 px-1

                      focus:outline-none focus:ring-2 focus:ring-color-5
                    `}
                    id="formImageURLS"
                    name="formImageURLS"
                    defaultValue={url}
                    onChange={
                      (e) =>
                        setFormImageURLS((prev) =>
                          prev.map((u, i) =>
                            i === index ? e.target.value : u,
                          ),
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  />
                  <button
                    type="button"
                    className={`
                      ml-1 h-6 rounded border-2 border-color-1 px-1 bg-color-white transition-all
                      ease-in-out

                      active:opacity-50

                      hover:border-color-5 hover:bg-color-4 hover:text-white
                    `}
                    onClick={
                      () =>
                        setFormImageURLS((prev) =>
                          prev.filter((_, i) => i !== index),
                        )
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                  >
                    -
                  </button>
                </div>
                {url !== '' && (
                  <img
                    className="mb-2 w-[200px] rounded"
                    alt="imageUrl"
                    src={`${url}/m/200x0`}
                  />
                )}
              </div>
            ))}
          </div>
          {/* END IMAGE URLS */}
        </div>
        {formButtons(id, handleDelete, handleSave)}
      </form>
    );
  }

  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] overflow-y-scroll rounded border-4 border-color-4 bg-white pt-4
      `}
    >
      <h1 className="px-4">Instructor Details</h1>
      {detailsForm()}
    </div>
  );
}

export default Instructor;
