/* eslint-disable operator-linebreak */
/**
 * Calculates the expiry date of a transaction based on the transaction date and duration.
 *
 * @param {Object} transaction - The transaction object containing date, duration, and type.
 * @return {Date} The calculated expiry date.
 */
export function getTransactionExpiryDate(transaction) {
  const expiryDate = new Date(transaction.dateTime);

  if (transaction.duration.type === 'day') {
    expiryDate.setDate(expiryDate.getDate() + transaction.duration.value);
  }
  if (transaction.duration.type === 'month') {
    expiryDate.setMonth(expiryDate.getMonth() + transaction.duration.value);
  }
  if (transaction.duration.type === 'year') {
    expiryDate.setFullYear(
      expiryDate.getFullYear() + transaction.duration.value,
    );
  }
  expiryDate.setDate(expiryDate.getDate() + 1);
  return expiryDate;
}

/**
 * Checks if a transaction is currently active.
 *
 * @param {Object} transaction - The transaction object to check.
 * @return {boolean} Returns true if the transaction is active, false otherwise.
 */
export function transactionActive(transaction) {
  if (getTransactionExpiryDate(transaction) >= new Date()) {
    return true;
  }
  return false;
}

/**
 * Converts a given date object to a formatted text string.
 *
 * @param {Date} date - The date object to be converted.
 * @return {string} The formatted text string representing the given date.
 */
export function dateToText(date) {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${months[month]} ${day}, ${year}`;
}

/**
 * Checks if a given course is active
 * based on if transaction dates fall within the transaction duration.
 *
 * @param {Object} course - The course object to check.
 * @param {Array} transactions - The list of transactions to check against.
 * @return {boolean} Returns true if the course is active, otherwise false.
 */
export function courseActive(course, transactions) {
  let active = false;
  if (Object.prototype.toString.apply(transactions) === '[object Array]') {
    transactions?.forEach((transaction) => {
      if (transactionActive(transaction)) {
        if (transaction.courseId === course._id) {
          active = true;
        }
      }
    });
  }

  return active;
}

export function findBiggestPromotion(promotions, course) {
  let biggestPromotion;
  promotions.forEach((p) => {
    if (
      new Date(p.startDate) <= new Date() &&
      new Date(p.endDate) >= new Date()
    ) {
      if (!biggestPromotion) {
        biggestPromotion = p;
      } else {
        const lastPrice =
          biggestPromotion.type === 'percentage'
            ? course.cost * (1 - biggestPromotion.value / 100)
            : Math.max(0, course.cost - biggestPromotion.value);
        const newPrice =
          p.type === 'percentage'
            ? course.cost * (1 - p.value / 100)
            : Math.max(0, course.cost - p.value);
        if (lastPrice > newPrice) biggestPromotion = p;
      }
    }
  });
  return biggestPromotion;
}
