import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';

import { Toaster } from 'react-hot-toast';
import Layout from './layout/Layout';

import {
  Home,
  Courses,
  Course,
  Lesson,
  Instructors,
  Instructor,
  Posts,
  Post,
  Account,
  Disclaimer,
  Privacy,
  Cookies,
  ErrorPage,
  Admin,
} from './routes';

import { AuthContext } from './utils/AuthContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <Layout outlet={<ErrorPage />} />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: 'courses',
        element: <Courses />,
      },
      {
        path: 'courses/:courseId',
        element: <Course />,
      },
      {
        path: 'lessons/:courseId/:lessonId',
        element: <Lesson />,
      },
      {
        path: 'instructors',
        element: <Instructors />,
      },
      {
        path: 'instructors/:instructorId',
        element: <Instructor />,
      },
      {
        path: 'posts',
        element: <Posts />,
      },
      {
        path: 'posts/:postId',
        element: <Post />,
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'disclaimer',
        element: <Disclaimer />,
      },
      {
        path: 'privacy',
        element: <Privacy />,
      },
      {
        path: 'cookies',
        element: <Cookies />,
      },
      {
        path: 'admin',
        element: <Admin />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthContext>
      <RouterProvider router={router} />
      <Toaster />
    </AuthContext>
  </React.StrictMode>,
);
