import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import { cardContainerHTMLGenerator } from '../../utils/componentGeneration';

function Posts() {
  const [posts, setPosts] = useState();
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1); // TODO allow user to change pagination
  const pageLimit = 30; // TODO dont hard code

  useEffect(() => {
    document.title = 'Posts';
  }, []);
  async function fetchData() {
    await fetch(
      `/.netlify/functions/posts?fields=title&fields=coverImageURL&fields=date&recent=${pageLimit}&page=${pageNumber}`,
    )
      .then((response) => response.json())
      .then((response) => setPosts(response));
  }
  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  return (
    <div>
      <h1 className="mt-4 w-full text-center text-3xl">Posts</h1>
      <div className="flex justify-center">
        <BarLoader color="#023059" className="m-4" loading={!posts} />
      </div>
      {cardContainerHTMLGenerator(
        posts?.map((post) => ({
          _id: post._id,
          coverImageURL: post.coverImageURL,
          slug: '/posts/',
          text_1: post.title,
          text_2: post.date.split('T')[0],
        })),
      )}
    </div>
  );
}

export default Posts;
