import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  XIcon,
} from 'react-share';

function SocialMediaButtons({ title }) {
  return (
    <>
      <EmailShareButton subject={title} url={window.location.href}>
        <EmailIcon size={32} round />
      </EmailShareButton>
      <FacebookShareButton url={window.location.href}>
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <FacebookMessengerShareButton url={window.location.href}>
        <FacebookMessengerIcon size={32} round />
      </FacebookMessengerShareButton>
      <RedditShareButton title={title} url={window.location.href}>
        <RedditIcon size={32} round />
      </RedditShareButton>
      <TwitterShareButton title={title} url={window.location.href}>
        <XIcon size={32} round />
      </TwitterShareButton>
      <VKShareButton title={title} url={window.location.href}>
        <VKIcon size={32} round />
      </VKShareButton>
    </>
  );
}

export default SocialMediaButtons;
