import React from 'react';

function UserSelectionPopup({
  users,
  formUserId,
  setFormUserId,
  setOpenUserIdPopup,
}) {
  return (
    <div
      className={`
        max-h-[95vh] min-w-[500px] max-w-[95vw] overflow-y-scroll rounded border-4 border-color-4
        bg-white text-white
      `}
    >
      <div className="sticky top-0">
        <h1 className="bg-white px-4 pt-4">Select User</h1>
        <div className="grid grid-cols-2 bg-color-1 text-center">
          <div>ID</div>
          <div>Email</div>
        </div>
      </div>
      <div className="grid grid-cols-2 bg-color-2 text-center">
        {users?.map((u) => (
          <>
            <button
              className={`
                ${formUserId === u._id ? 'bg-color-1' : ''}

                border-b-2 border-b-color-1

                hover:text-color-5
              `}
              type="button"
              onClick={() => {
                setFormUserId(u._id);
                setOpenUserIdPopup(false);
              }}
            >
              {u._id}
            </button>
            <button
              className={`
                ${formUserId === u._id ? 'bg-color-1' : ''}

                border-b-2 border-b-color-1

                hover:text-color-5
              `}
              type="button"
              onClick={() => {
                setFormUserId(u._id);
                setOpenUserIdPopup(false);
              }}
            >
              {u.email}
            </button>
          </>
        ))}
      </div>
    </div>
  );
}

export default UserSelectionPopup;
