import React, { useEffect, useState } from 'react';
import RecordList from './RecordList';

function Admin() {
  const tabs = [
    'users',
    'transactions',
    'instructors',
    'posts',
    'courses',
    'lessons',
    'testimonials',
    'promotions',
  ];
  const [selectedTab, setSelectedTab] = useState(
    localStorage.getItem('adminTab') || tabs[0],
  );
  useEffect(() => {
    document.title = 'Admin';
  }, []);

  function sideBar() {
    return (
      <div className="flex flex-col bg-color-4 text-color-5">
        {tabs.map((tab) => (
          <button
            className={`
              p-2 text-right capitalize transition-all ease-in-out

              active:bg-color-5 active:text-white

              hover:opacity-50

              ${selectedTab === tab ? 'text-white' : ''}
            `}
            type="button"
            onClick={() => {
              localStorage.setItem('adminTab', tab);
              setSelectedTab(tab);
            }}
          >
            {tab}
          </button>
        ))}
      </div>
    );
  }

  return (
    <div className="flex flex-grow">
      {sideBar()}
      <div className="w-full">
        <RecordList collectionName={selectedTab} />
      </div>
    </div>
  );
}

export default Admin;
