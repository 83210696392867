import React, { useEffect } from 'react';

function Privacy() {
  useEffect(() => {
    document.title = 'Privacy';
  }, []);
  return (
    <div>
      <div>
        <div className="w-full">
          <h2 className="m-auto max-w-2xl p-4 text-3xl">Privacy Policy</h2>
        </div>
        <div className="bg-color-2">
          <div className="m-auto flex max-w-2xl flex-col gap-2 p-4 text-white">
            <div className="mt-4 flex flex-col gap-2">
              <p>
                At Online Academy of Chinese Arts, accessible from
                onlineacademyofchinesearts.com, one of our main priorities is
                the privacy of our visitors. This Privacy Policy document
                contains types of information that is collected and recorded by
                Online Academy of Chinese Arts and how we use it.
              </p>

              <p>
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us at
                info@onlineacademyofchinesearts.com.
              </p>

              <p>
                This Privacy Policy applies only to our online activities and is
                valid for visitors to our website with regards to the
                information that they shared and/or collect in Online Academy of
                Chinese Arts. This policy is not applicable to any information
                collected offline or via channels other than this website. Our
                Privacy Policy was created with the help of the&nbsp;
                <a href="https://www.cookiepolicygenerator.com/privacy-policy-generator/">
                  Privacy Policy Generator
                </a>
                .
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>1. Consent</h3>

              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its terms.
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>2. Information We Collect</h3>

              <p>
                We collect email addresses to serve as usernames and for email
                notifications. Users may opt out of promotional emails, although
                emails regarding account and site maintenance are necessary and
                cannot be opted out of.
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>3. How We Use Your Information</h3>

              <p>
                We use the information we collect in various ways, including to:
              </p>

              <div className="m-4 flex flex-col">
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>
                  Develop new products, services, features, and functionality
                </li>
                <li>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>4. Google Analytics</h3>

              <p>
                We utilize Google Analytics to gather insights into user
                interactions with our website. Google Analytics collects data
                such as the pages visited, time spent on each page, the type of
                device used, and demographic information, which helps us improve
                our services and user experience.
              </p>

              <p>
                By using our site or application, you acknowledge and agree to
                the collection and processing of data by Google Analytics as
                described above. Google Analytics may set cookies or utilize
                other tracking technologies to gather this information. You can
                learn more about Google Analytics and how it collects and
                processes data by reviewing Google&apos;s Privacy Policy
                available at&nbsp;
                <a href="https://policies.google.com/privacy">
                  https://policies.google.com/privacy
                </a>
                .
              </p>

              <p>
                If you wish to opt out of Google Analytics tracking, you can
                install the Google Analytics Opt-out Browser Add-on available
                at&nbsp;
                <a href="https://tools.google.com/dlpage/gaoptout">
                  https://tools.google.com/dlpage/gaoptout
                </a>
                .
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>5. Payment Portals</h3>

              <p>
                Please note that payment portals may collect data through their
                own means, and we do not have access to or control over this
                data. We encourage users to review the privacy policies of these
                third-party payment processors for more information on their
                data collection and processing practices.
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>
                6. CCPA Privacy Rights (Do Not Sell My Personal Information)
              </h3>

              <p>
                Under the CCPA, among other rights, California consumers have
                the right to:
              </p>
              <p>
                Request that a business that collects a consumer&apos;s personal
                data disclose the categories and specific pieces of personal
                data that a business has collected about consumers.
              </p>
              <p>
                Request that a business delete any personal data about the
                consumer that a business has collected.
              </p>
              <p>
                Request that a business that sells a consumer&apos;s personal
                data, not sell the consumer&apos;s personal data.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>7. GDPR Data Protection Rights</h3>

              <p>
                We would like to make sure you are fully aware of all of your
                data protection rights. Every user is entitled to the following:
              </p>
              <p>
                The right to access – You have the right to request copies of
                your personal data. We may charge you a small fee for this
                service.
              </p>
              <p>
                The right to rectification – You have the right to request that
                we correct any information you believe is inaccurate. You also
                have the right to request that we complete the information you
                believe is incomplete.
              </p>
              <p>
                The right to erasure – You have the right to request that we
                erase your personal data, under certain conditions.
              </p>
              <p>
                The right to restrict processing – You have the right to request
                that we restrict the processing of your personal data, under
                certain conditions.
              </p>
              <p>
                The right to object to processing – You have the right to object
                to our processing of your personal data, under certain
                conditions.
              </p>
              <p>
                The right to data portability – You have the right to request
                that we transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </p>
              <p>
                If you make a request, we have one month to respond to you. If
                you would like to exercise any of these rights, please contact
                us.
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <h3>8. Children&apos;s Information</h3>

              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
              </p>

              <p>
                Online Academy of Chinese Arts does not knowingly collect any
                Personal Identifiable Information from children under the age of
                13. If you think that your child provided this kind of
                information on our website, we strongly encourage you to contact
                us immediately and we will do our best efforts to promptly
                remove such information from our records.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
